@tailwind base;
@tailwind components;
@tailwind utilities;

select:not([size]) {
  padding-right: 20px !important;
}
button {
  cursor: pointer !important;
}
.TEXT > h2 {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 50%,
    rgb(37, 37, 37) 50%
  );

  /* background-size: 200% 100%; */
  /* background-position-x: 100%; */
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  /* margin-bottom: -250px; */
  /* margin-left: 50px; */
  line-height: 1.2;
}

/* .full_page_animation {
  position: absolute;
  z-index: 99999;
} */
/* .homeProject_Wrapper {
  height: 100vh;
  position: relative;
} */

/* .homeProject_Item:not(:first-child) {
  position: absolute;
  top: 100vh;
  width: 100%;
} */
.split-parent {
  overflow: hidden;
}

.split-child {
  /* display: inline-block; */
  padding-bottom: 12px;
}
.circle_svg {
  position: absolute;
  z-index: 99999;
  visibility: hidden;
}
video {
  &::-webkit-media-controls {
    display: none !important;
  }

  &::-moz-media-controls {
    display: none !important;
  }

  &::-ms-media-controls {
    display: none !important;
  }
}

.highlight_yellow {
  color: var(--limeMist_clr) !important;
}
ellipse {
  display: none;
}
.loader-wrap {
  position: absolute;
  z-index: 999999;
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: hidden;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.loader-wrap svg {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 110vh;
}

.loader-wrap .loader-wrap-heading h1 {
  font-size: 120px;
  z-index: 20;
  color: #e0e0e0;
  text-transform: uppercase;
  font-weight: lighter;
  font-family: 'Ogg';
}

.bg-gray-900 {
  --tw-bg-opacity: 0.5;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

/* .svg-path {
  height: 30000px;
  width: 3000px;
  position: absolute; 
  z-index: 9999;
} */
.text > h2 {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 50%,
    rgb(37, 37, 37) 50%
  );
  background-size: 200%;
  background-position-x: 100%;
  color: transparent;
  background-clip: text;
}

.text-2 > h2 {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 50%,
    rgb(37, 37, 37) 50%
  );
  background-size: 200%;
  background-position-x: 100%;
  color: transparent;
  background-clip: text;
}
.text-3 > h2 {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 50%,
    rgb(37, 37, 37) 50%
  );
  background-size: 200%;
  background-position-x: 100%;
  color: transparent;
  background-clip: text;
}
.text-4 > h2 {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 50%,
    rgb(37, 37, 37) 50%
  );
  background-size: 200%;
  background-position-x: 100%;
  color: transparent;
  background-clip: text;
}

.colorChange.white {
  color: white;
}

#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #f00;
}

#loading-content:after {
  content: '';
  position: absolute;
  border: 3px solid #0f0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: '';
  position: absolute;
  border: 3px solid #00f;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #4d658d;
  border-bottom-color: #4d658d;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #d4cc6a;
  border-bottom-color: #d4cc6a;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417c;
  border-bottom-color: #84417c;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


