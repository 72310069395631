// @import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*,
::after,
::before {
  box-sizing: border-box;

}

// html {
//   scroll-behavior: smooth;
// }

// body {
//   touch-action: pan-x;
//   scroll-behavior: auto;
//   overscroll-behavior: none;
//   // overflow-x: hidden;
// }

html,
body {
  // height: 100%;
  margin: 0;
  padding: 0;


  // overflow: scroll !important;
  // font-family: var(--poppins_fontFamily) !important;
  background: var(--background-color) !important;
}

// #root {
//   height: 100%;
// }

@font-face {
  font-family: 'Atyp Display';
  src: url('./assets/Fonts/Atyp-Heading/AtypText-Thin.woff') format('woff'),
    url('./assets/Fonts/Atyp-Heading/AtypText-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Atyp Display';
  src: url('./assets/Fonts/Atyp-Heading/AtypDisplay-Light.woff') format('woff'),
    url('./assets/Fonts/Atyp-Heading/AtypDisplay-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Atyp Display';
  src: url('./assets/Fonts/Atyp-Heading/AtypDisplay-Regular.woff') format('woff'),
    url('./assets/Fonts/Atyp-Heading/AtypDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Atyp Display';
  src: url('./assets/Fonts/Atyp-Heading/AtypDisplay-Medium.woff') format('woff'),
    url('./assets/Fonts/Atyp-Heading/AtypDisplay-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Atyp Display';
  src: url('./assets/Fonts/Atyp-Heading/AtypDisplay-Semibold.woff') format('woff'),
    url('./assets/Fonts/Atyp-Heading/AtypDisplay-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Atyp Display';
  src: url('./assets/Fonts/Atyp-Heading/AtypDisplay-Bold.woff') format('woff'),
    url('./assets/Fonts/Atyp-Heading/AtypDisplay-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}



// Helvetica

// @font-face {
//   font-family: 'Helvetica';
//   src: url('./assets/Fonts/Helvetica/Helvetica-Light.woff') format('woff'),
//     url('./assets/Fonts/Helvetica/Helvetica-Light.woff2') format('woff2');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Helvetica';
//   src: url('./assets/Fonts/Helvetica/Helvetica.woff') format('woff'),
//     url('./assets/Fonts/Helvetica/Helvetica.woff2') format('woff2');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Helvetica';
//   src: url('./assets/Fonts/Helvetica/Helvetica-Bold.woff') format('woff'),
//     url('./assets/Fonts/Helvetica/Helvetica-Bold.woff2') format('woff2');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }


:root {
  --white_clr: #ffffff;
  --black_clr: #000000;
  --verifiedBlack: #232327;
  --errorText_clr: #ec171c;
  --SteelWool_clr: #777777;
  --bloodDonor_clr: #ec171c;
  --ExpressionismGreen_clr: #4cbc9a;
  --cabbagePatch_clr: #95c05f;
  --greenGoneWild_clr: #6e9d31;
  --blankaGreen_clr: #92d43f;
  --limeMist_clr: #dbffad;
  --dreamyCloudColor: #e5e7eb;
  --cerebralGrey_clr: #cccccc;
  --drWhite_clr: #fafafa;
  --whitesmoke_clr: #f5f5f5;
  --blackSuede_clr: #434343;
  --cursedBlack_clr: #121315;
  // --poppins_fontFamily: "Poppins", sans-serif;
  // --Syne_fontFamily: 'Syne', sans-serif;
  --AtypDisplay_fontFamily: 'Atyp Display';
  --Syne_fontFamily: "Poppins", sans-serif;
  // --Syne_fontFamily: "Roboto", sans-serif;

  // --Syne_fontFamily: 'Helvetica';


  --fs10px: 10px;
  --fs12px: 12px;
  --fs14px: 14px;
  --fs16px: 16px;
  --fs18px: 18px;
  --fs20px: 20px;
  --fs22px: 22px;
  --fs24px: 24px;
  --fs26px: 26px;
  --fs30px: 30px;
  --fs32px: 32px;
  --fs34px: 34px;
  --fs36px: 36px;
  --fs40px: 40px;
  --fs48px: 48px;
  --fs74px: 74px;
  --fs96px: 96px;
  --fs140px: 140px;
  --fw300: 300;
  --fw400: 400;
  --fw500: 500;
  --fw600: 600;
  --fw700: 700;
  --cabbagePatchFilter: brightness(0) saturate(100%) invert(80%) sepia(9%) saturate(2112%) hue-rotate(43deg) brightness(89%) contrast(85%);
  --bloodDonorFilter: brightness(0) saturate(100%) invert(14%) sepia(69%) saturate(4293%) hue-rotate(346deg) brightness(113%) contrast(114%);

  --greenGoneWildFilter: brightness(0) saturate(100%) invert(56%) sepia(15%) saturate(1690%) hue-rotate(44deg) brightness(95%) contrast(86%);
  --verifiedBlackFilter: brightness(0) saturate(100%) invert(3%) sepia(4%) saturate(4749%) hue-rotate(201deg) brightness(83%) contrast(76%);
  --limeMistFilter: brightness(0) saturate(100%) invert(98%) sepia(5%) saturate(3778%) hue-rotate(37deg) brightness(105%) contrast(104%);
}

.loader_text {
  font-family: var(--AtypDisplay_fontFamily) !important;
  line-height: 1.5;
  color: var(--drWhite_clr);
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  text-align: center;
}

.loader_text span {
  color: var(--blankaGreen_clr);
}

// .loader_text span::before {
//   font-family: var(--AtypDisplay_fontFamily) !important;
//   color: var(--blankaGreen_clr);
//   line-height: 100px;
//   font-size: 24px;
//   position: fixed;
//   font-weight: 600;
//   content: "Architects.";
//   animation: animate infinite 3.5s;
//   padding-left: 10px;
// }

// @keyframes animate {

//   0% {
//     content: "Architects.";
//   }

//   50% {
//     content: "Planners.";
//   }

//   70% {
//     content: "Designers.";
//   }
// }

.fontSize10 {
  font-size: var(--fs10px);
}

.fontSize12 {
  font-size: var(--fs12px);
}

.fontSize14 {
  font-size: var(--fs14px);
}

.fontSize16 {
  font-size: var(--fs16px);
}

.fontSize18 {
  font-size: var(--fs18px);
}

.fontSize20 {
  font-size: var(--fs20px);
}

.fontSize22 {
  font-size: var(--fs22px);
}

.fontSize24 {
  font-size: var(--fs24px);
}

.fontSize26 {
  font-size: var(--fs26px);
}

.fontSize30 {
  font-size: var(--fs30px);
}

.fontSize32 {
  font-size: var(--fs32px);
}

.fontSize34 {
  font-size: var(--fs34px);
}

.fontSize36 {
  font-size: var(--fs36px);
}

.fontSize40 {
  font-size: var(--fs40px);
}

.fontSize48 {
  font-size: var(--fs48px);
}

.fontSize74 {
  font-size: var(--fs74px);
}

.fontSize96 {
  font-size: var(--fs96px);
}

.fontSize140 {
  font-size: var(--fs140px);
}

.fontWeight300 {
  font-weight: var(--fw300);
}

.fontWeight400 {
  font-weight: var(--fw400);
}

.fontWeight500 {
  font-weight: var(--fw500);
}

.fontWeight600 {
  font-weight: var(--fw600);
}

.fontWeight700 {
  font-weight: var(--fw700);
}

.syne_Font {
  font-family: var(--Syne_fontFamily);
}

.atypDisplay_Font {
  font-family: var(--AtypDisplay_fontFamily) !important;
}

.display_block {
  display: block;
}

.displayFlex {
  display: flex;
}

.desktopFlex_mobileBlock {
  display: flex;
}

.alignItem_center {
  align-items: center;
}
.alignItem_baseline {
    align-items: baseline;
}

.alignItem_end {
  align-items: end;
}

.justifyContent_spacebetween {
  justify-content: space-between;
}

.flexDirection_column {
  flex-direction: column;
}

.justifyContent_center {
  justify-content: center;
}

.justifyContent_end {
  justify-content: end;
}

.gap12 {
  gap: 12px;
}

.gap16 {
  gap: 16px;
}

.marginLeftRight_Auto {
  margin: 0px auto !important;
}

.marginLeft_Auto {
  margin-left: auto !important;
}

.gap30 {
  gap: 30px;
}

.gap104 {
  gap: 104px;
}

.textalign_center {
  text-align: center;
}

.textalign_right {
  text-align: right;
}

.textdecoration_underline {
  text-decoration: underline;
}

.textalign_right {
  text-align: right;
}

.borderRadius30 {
  border-radius: 30px;
}

.pzero {
  padding: 0 !important;
}

.ml_auto {
  margin-left: auto !important;
}

.mlr_auto {
  margin-inline: auto;
}

.height70px {
  height: 70px;
}

.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
}

.top_20 {
  top: 20px;
}

.right_20 {
  right: 20px;
}

.width100per {
  width: 100%;
}

.height100per {
  height: 100%;
}

.width24px {
  width: 24px;
}

.width250px {
  width: 250px;
}

.width18px {
  width: 18px;
}

.width20px {
  width: 20px;
}

.mb_24 {
  margin-bottom: 24px !important;
}

.mb_20 {
  margin-bottom: 20px !important;
}

.mb_30 {
  margin-bottom: 30px !important;
}

.mb_34 {
  margin-bottom: 34px !important;
}

.mb_32 {
  margin-bottom: 32px !important;
}

.mb_40 {
  margin-bottom: 40px !important;
}

.mt_8 {
  margin-top: 8px !important;
}

.mb_8 {
  margin-bottom: 8px !important;
}

.mb_12 {
  margin-bottom: 12px !important;
}

.mb_16 {
  margin-bottom: 16px !important;
}

.mr_12 {
  margin-right: 12px;
}

.transformRotateMin180deg {
  transform: rotate(-180deg);
}


.pblock20 {
  padding-block: 20px;
}

.pr40 {
  padding-right: 40px;
}

.pb_150 {
  padding-bottom: 150px;
}

.pb_100 {
  padding-bottom: 100px;
}

.pb_80 {
  padding-bottom: 80px;
}

.zIndex999 {
  z-index: 999;
}

.zIndex1 {
  z-index: 1;
}

.whiteClr_text {
  color: var(--white_clr);
  line-height: 1.5;
  margin: 0px;
}

.blackClr_text {
  color: var(--black_clr);
  line-height: 1.5;
  margin: 0px;
}

.bloodDonorClr_text {
  color: var(--bloodDonor_clr) !important;
  line-height: 1.5;
  margin: 0px;
}

.cerebralGreyClr_text {
  color: var(--cerebralGrey_clr) !important;
  line-height: 1.5;
  margin: 0px;
}

.drWhiteClr_text {
  color: var(--drWhite_clr) !important;
  line-height: 1.5;
  margin: 0px;
}

.whitesmokeClr_text {
  color: var(--whitesmoke_clr) !important;
  line-height: 1.5;
  margin: 0px;
}

.blackSuedeClr_text {
  color: var(--blackSuede_clr) !important;
  line-height: 1.5;
  margin: 0px;
}

.cabbagePatchFilter {
  filter: var(--cabbagePatchFilter);
}

.bloodDonorFilter {
  filter: var(--bloodDonorFilter);
}

.greenGoneWildFilter {
  filter: var(--greenGoneWildFilter);
}

.verifiedBlackFilter {
  filter: var(--verifiedBlackFilter);
}

.limeMistFilter {
  filter: var(--limeMistFilter);
}

.SteelWoolClr_text {
  color: var(--SteelWool_clr);
  line-height: 1.5;
  margin: 0px;
}

.greenGoneWildClr_text {
  color: var(--greenGoneWild_clr) !important;
  line-height: 1.5;
  margin: 0px;
}

.blankaGreenClr_text {
  color: var(--blankaGreen_clr) !important;
  line-height: 1.5;
  margin: 0px;
}

.limeMistClr_text {
  color: var(--limeMist_clr);
  line-height: 1.5;
  margin: 0px;
}

.cursorPointer {
  cursor: pointer;
}

.emptyBtn {
  background-color: transparent;
  outline: unset;
  border: unset;
  padding: 0px;
}

.emptyList {
  list-style: none;
  margin: unset;
  padding: 0px;
}

.actionBtn {
  background-color: #f5f5f5;
  outline: unset;
  border: 1px solid #e5e7eb;
  padding: 4px;
  border-radius: 7px;
}

.actionBtn img {
  width: 22px;
}

.float_right {
  float: right;
}





.sectionTitle h2 {
  font-size: 96px;
  // line-height: 100px;
  line-height: 90px;
  font-weight: 600;
  color: var(--drWhite_clr);
  z-index: 999;
  position: relative;
  text-transform: uppercase;
}

// .accountWrapper {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100%;
// }

.loginFrame {
  // background-color: #fff;
  // padding: 24px;
  // border-radius: 12px;
  // width: 350px;
  // margin: auto;
  // z-index: 999;
  // position: relative;
  position: relative;
  height: 100%;
}

.loginFrame::before {
  content: '';
  position: absolute;
  top: 200px;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  // background: linear-gradient(to top, var(--blackClr_text), transparent);
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: hard-light;
  opacity: 0.7;
}

.loginFrame .loginFrame_img img {
  width: 100%;
  object-fit: cover;
  height: calc(707px - 100%);
  // min-height: 100%;
  // careerPopup-height: 100%;
}

.loginFrame_caption {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  bottom: 60px;
  top: 0;
  left: 0;
  right: 0;
  padding: 40px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// .loginFrame_caption>div:last-child {
//     padding-bottom: 40px;
// }

.accountWrapper .slick-prev:before {
  content: '' !important;
  background: url('./assets/images/left_arrow.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  width: 35px;
  height: 23px;
  display: block;
}

.accountWrapper .slick-next:before {
  content: '' !important;
  background: url('./assets/images/right_arrow.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  width: 35px;
  height: 23px;
  display: block;
}

.accountWrapper .slick-next {
  background: #ffffff3e !important;
  border-radius: 7px;
  right: 40px !important;
  backdrop-filter: blur(14px);
  --webkit-backdrop-filter: blur(14px);
  // box-shadow: rgb(2 0 / 100%) 0px 10px 50px;
}

.accountWrapper .slick-prev {
  // background: #000000 !important;
  border-radius: 7px;
  right: 100px !important;
  left: unset !important;
  z-index: 999;
  backdrop-filter: blur(14px);
  --webkit-backdrop-filter: blur(14px);
  box-shadow: rgb(0 0 0 / 100%) 0px 10px 50px;
}

.slick-prev:before,
.slick-next:before {
  opacity: unset !important;
}

.accountWrapper .slick-prev,
.accountWrapper .slick-next {
  border-radius: 7px;
  top: unset !important;
  bottom: 30px;
  width: 47px !important;
  height: 41px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.MainInput {
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--black_clr);
  border: 1px solid #e6e6f2;
  background: transparent;
  outline: unset;
  height: 40px;
  border-radius: 10px;
  padding: 0px 16px;
}

.width100per {
  width: 100%;
}

input[type='file']::file-selector-button {
  // visibility: hidden;
  display: none;
}

// .uploadFileItem{
//   background: #777777;
//   color: #fff;
//   border-radius: 30px;
//   font-size: 18px;
//   line-height: 1.5;
//   font-weight: 400;
//   font-family: var(--Syne_fontFamily);
//   display: inline-flex;
//   align-items: center;
//   gap: 16px;
//   padding:4px 16px;
//   cursor: pointer;
// }

.MainButton {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  outline: unset;
  height: 40px;
  border-radius: 10px;
  padding: 0px 16px;
  font-family: var(--AtypDisplay_fontFamily);
  display: flex;
  align-items: center;
  gap: 12px;
}

.MainButton:hover {
  opacity: 0.8;
}

.secondaryBtn {
  background: var(--verifiedBlack);
  border: 1px solid var(--verifiedBlack);
  color: var(--drWhite_clr);
  border-radius: 80px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--AtypDisplay_fontFamily);
  height: 50px;
  cursor: pointer;
  padding: 0px 24px;
  opacity: 0.8;
}

.greenGoneWild_btnclr {
  border: 1px solid var(--greenGoneWild_clr);
  background: var(--greenGoneWild_clr);
  color: var(--white_clr);
}

.bloodDonor_btnclr {
  background-color: var(--bloodDonor_clr);
  border: 1px solid var(--bloodDonor_clr);
  color: var(--white_clr);
}

// .MainButton:hover {
//   color: var(--cabbagePatch_clr);
//   border: 1px solid var(--cabbagePatch_clr);
//   background: transparent;
// }

// .MainButton:hover img {
//   filter: brightness(0) saturate(100%) invert(49%) sepia(87%) saturate(7303%)
//     hue-rotate(343deg) brightness(96%) contrast(94%);
// }

.page_wrapper {
  position: relative;
  display: flex;
  overflow-x: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100%;
  background: #f5f5f5;
}

.sidebar_wrapper {
  width: 260px;
  background-color: #05050b;
  // box-shadow: rgb(149 157 165 / 20%) 0px 0px 40px;
  padding-block: 0px;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  // height: calc(100% - 32px);
  justify-content: space-between;
  z-index: 999;
  transition: transform 0.25s ease-in-out, inline-size 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}

.sidebar_wrapper>div:first-child {
  padding: 16px;
}

// .sidebar_wrapper>div:last-child {
//     padding: 16px;
//     background: #19191f;
//     border-bottom-left-radius: 1rem;
//     border-bottom-right-radius: 1rem;
// }

.sidebar_wrapper ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.sidebar_wrapper ul li {
  margin-bottom: 8px;
}

.sidebar_wrapper ul li button {
  background-color: transparent;
  outline: unset;
  border: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  text-decoration: none;
  color: #e7eef8;
  position: relative;
  padding: 12px 16px;
  // margin: auto;
  opacity: 0.7;
}

.Main_menu ul li:last-child {
  margin-bottom: 0px;
}



// .sidebar_wrapper ul li button img{
//   width: 22px;
// }





.sidebar_wrapper ul li button.active {
  background: #19191f;
  color: #fff;
  border-radius: 10px;
  opacity: unset;
}

.sidebar_wrapper ul li button:hover {
  background: var(--greenGoneWild_clr);
  color: #fff;
  border-radius: 10px;
  opacity: unset;
}

.sidebar_wrapper ul li button.active img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(92%) saturate(7491%) hue-rotate(220deg) brightness(99%) contrast(101%);
}

.content_wrapper {
  // width: calc(100% - 260px);
  width: 100%;
  height: 100%;
  margin-left: 260px;
  // margin-left: 276px;
  display: inline-block;
  overflow: hidden;
}

.navbarHeader {
  background-color: #fff;
  // box-shadow: rgb(149 157 165 / 20%) 0px 0px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
}

.adminLogo {
  padding: 24px 0px 34px;
  text-align: center;
}

.adminLogo img {
  width: 230px;
}

.MainContent {
  margin: 24px;
}

.userProfile .btn-primary {
  background-color: unset;
  border: 1px solid transparent;
  border-radius: 0px;
  padding: 0px;
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
}

.userProfile .btn-primary:hover {
  background-color: transparent;
  border-color: transparent;
  color: #000;
}

.userProfile .btn-primary:focus {
  background-color: transparent;
  border-color: transparent;
  color: #000;
}

.userProfile .btn:focus-visible {
  box-shadow: unset;
}

.userProfile .btn-check:checked+.btn,
.userProfile .btn.active,
.userProfile .btn.show,
.userProfile .btn:first-child:active,
.userProfile :not(.btn-check)+.btn:active .userProfile .btn-primary:active {
  background-color: transparent;
  color: #000;
  border-color: transparent;
}

.userProfile img {
  width: 35px;
}

.userProfile .dropdown-menu.show {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: unset;
  border-radius: 5px !important;
  top: 20px !important;
}

.userprofileText {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.userProfile .dropdown-menu.show a {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: #a0a0a0;
  padding: 8px 12px;
}

.userProfile .dropdown-item:active {
  background-color: #f8f9fa !important;
}

.userProfile .dropdown-toggle {
  position: relative;
}

.dashboard_item {
  background-color: #fff;
  border-radius: 24px;
  padding: 16px;
  border: 1px solid #e5e7eb;
}

.whiteFrame {
  background: #fff;
  border-radius: 7px;
  padding: 24px;
  border-radius: 12px;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.logoAcc {
  text-align: center;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.logoAcc img {
  width: 260px;
}

.adminblog_frame img {
  width: 120px;
  border-radius: 10px;
}

.Table_Wrapper table {
  width: 100%;
  margin: 0px;
  border-collapse: separate;
  border-spacing: 0px;
  background-color: var(--white_clr);
  border-radius: 10px;
  border: 1px solid #e5e7eb;
  // box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  // box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.2), 2px -2px 4px rgba(0, 0, 0, 0.2);
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  // box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.2), 2px -2px 4px rgba(0, 0, 0, 0.2);
}

.Table_Wrapper thead {
  background-color: #f8f8f8;
}

.Table_Wrapper thead tr th:first-child {
  border-top-left-radius: 10px;
}

.Table_Wrapper thead tr th:last-child {
  border-top-right-radius: 10px;
}

.Table_Wrapper thead tr th {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  color: #777;
  padding: 12px 8px;
  border-bottom: 1px solid #e5e7eb;
}

// .Table_Wrapper tbody tr td:first-child {
//   border-top-left-radius: 8px;
//   border-bottom-left-radius: 8px;
// }

// .Table_Wrapper tbody tr td:last-child {
//   border-top-right-radius: 8px;
//   border-bottom-right-radius: 8px;
// }

.Table_Wrapper tbody tr:hover {
  background-color: var(--bleachedSilkColor);
}

.mandatory::after {
  content: "*";
  color: red;
  font-size: 12px;
  margin-left: 2px;
}

.Table_Wrapper tbody tr td {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--black_clr);
  padding: 14px 4px;
  // border-bottom: 1px solid #CCCCCC;
  border-bottom: 1px solid #e5e7eb;
}

.Table_Wrapper tbody tr:last-child td {
  border-bottom: unset;
}

.tableResponsive {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

/* width */
.tableResponsive::-webkit-scrollbar {
  width: 6px;
  height: 7px;
  border-radius: 5px;
}

/* Track */
.tableResponsive::-webkit-scrollbar-track {
  background: var(--dreamyCloudColor);
}

/* Handle */
.tableResponsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.tableResponsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// New Input start

.InputFrame:focus-within {
  border: 1px solid var(--blueoyster-cult_clr) !important;
}

.InputFrame:focus-within label {
  color: var(--blueoyster-cult_clr) !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.InputFrame {
  background: var(--pale-grey_clr);
  border: 1px solid var(--lavender-syrup_clr);
  border-radius: 7px;
  padding: 10px 20px;
  height: 70px;
}

.InputFrame input:focus {
  outline: unset;
  box-shadow: unset;
  border: unset;
}

.InputFrame select:focus {
  outline: unset;
  box-shadow: unset;
  border: unset;
}

.InputFrame input {
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  color: var(--black_clr);
  background: transparent;
  border: unset;
  outline: unset;
  padding: 0px;
}

.InputFrame input:disabled {
  cursor: not-allowed;
}

.InputFrame input::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: var(--silverpolish_clr);
}

.InputFrame select {
  padding: 0px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  color: var(--black_clr);
  background: transparent;
  border: unset;
  outline: unset;
  width: 100%;
  background: url('./assets/images/dropdown_arrow.svg') no-repeat;
  background-size: 13px;
  // background-position: calc(100% - 0.75rem) center !important;
  background-position: calc(100% - 0rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

.InputFrame select option {
  color: var(--black_clr);
  font-weight: 400 !important;
  font-size: 14px !important;
}

.UploadFrame {
  background: var(--pale-grey_clr);
  border: 1px solid var(--lavender-syrup_clr);
  border-radius: 7px;
  padding: 10px 20px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.roycroftPewterClr_text {
  color: var(--roycroft-pewter_clr);
  line-height: 1.5;
  margin: 0px;
}

:root {
  --roycroft-pewter_clr: #5e6366;
  --blueoyster-cult_clr: #5570f1;
  --pale-grey_clr: #fdfdff;
  --lavender-syrup_clr: #e6e6f2;
  --silverpolish_clr: #c6c6c6;
}



// login page new Design

// .auth-one-bg {
//     background-image: url("https://img.freepik.com/free-photo/standard-quality-control-collage-concept_23-2149595835.jpg?t=st=1713119753~exp=1713123353~hmac=a78a500f6868c589b1b65d55b456c8da15672ca5020dc331737e52687ef8f6c3&w=996");
//     background-position: center;
//     background-size: cover;
// }
// .auth-one-bg-position {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     width: 100%;
//     height: 380px;
// }
// .bg-overlay {
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     top: 0;
//     opacity: 0.7;
//     background-color: var(--cabbagePatch_clr);
// }

// .shape {
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     z-index: 1;
//     pointer-events: none;
// }

// .auth-page-content{
//     padding-top: 150px;
// }

// try height
.accountWrapper {
  height: 100vh;
}

.accountWrapper .container-fluid {
  height: 100%;
}

.accountWrapper .row.accountRow {
  height: 100%;
}

.accountWrapper .col-lg-8 {
  height: 100%;
}

.accountWrapper .slick-slider {
  height: 100%;
}

.accountWrapper .slick-list {
  height: 100%;
}

.accountWrapper .slick-track {
  height: 100%;
}

.accountWrapper .slick-slide>div {
  height: 100%;
}

.loginFrame.one {
  background: url('./assets/images/img_one.jpg') center center / cover no-repeat fixed;
  background-position: bottom;
  background-size: 100% 100vh;
}

.loginFrame.two {
  background: url('./assets/images/img_two.jpg') center center / cover no-repeat fixed;
  background-position: bottom;
  background-size: 100% 100vh;
}

.loginFrame.three {
  background: url('./assets/images/img_three.jpg') center center / cover no-repeat fixed;
  background-position: bottom;
  background-size: 100% 100vh;
}

.accountContent {
  padding: 120px 50px 60px;
  height: 100vh;
  overflow: auto;
  background: #fff;
}

.tableResponsive {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

/* width */
.tableResponsive::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 5px;
}

/* Track */
.tableResponsive::-webkit-scrollbar-track {
  background: var(--dreamyCloudColor);
}

/* Handle */
.tableResponsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.tableResponsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Website style start

// .sticky-top {
//   background: #121315;
//   position: sticky !important;
//   position: -webkit-sticky;
//   top: 0;
//   z-index: 1020;
// }

.sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  background: #121315;
  z-index: 100;
  inset-inline-end: 0;
  inset-inline-start: 0;
  width: 100%;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
  -moz-box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
  box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  border: none;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.customerLayout {
  position: relative;
  overflow: hidden;
}

header {
  padding: 30px 0px;
  position: relative;
  z-index: 1;
}

header .container nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Website style end

.brandLogo img:hover {
  transform: scale(1.1);
}

.brandLogo img {
  width: 300px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
}








.hamburger_menu {
  // width: 72px;
  width: 100px;
  height: 30px;
}

.hamburger_menu .lns {
  width: 4.688vw;
  height: 0.625vw;
  position: relative;
}

.hamburger_menu .lns .lns_nm.lns_nm02 {
  margin-top: 0.521vw;
}

// .hamburger_menu .lns_nm.lns_nm01 {
//   webkit-animation: lineHover 2s ease-in-out infinite;
//   animation: lineHover 2s ease-in-out infinite;
// }

// .hamburger_menu .lns_nm.lns_nm02 {
//   webkit-animation: lineHover 2s ease-in-out 60ms infinite;
//   animation: lineHover 2s ease-in-out 60ms infinite;
// }

.hamburger_menu:hover .lns_nm.lns_nm01 {
  webkit-animation: lineHover 0.35s ease-in-out;
  animation: lineHover 0.35s ease-in-out;
}

.hamburger_menu:hover .lns_nm.lns_nm02 {
  webkit-animation: lineHover 0.35s ease-in-out 60ms;
  animation: lineHover 0.35s ease-in-out 60ms;
}

.hamburger_menu .lns .lns_nm {
  display: block;
  transform-origin: center center;
  background: linear-gradient(to right, #45454b 0, #999ba4 100%);
  height: 1px;
  margin: 0;
  transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
  width: 100%;
  will-change: transform;
}

@keyframes lineHover {
  0% {
    transform: scaleX(1);
    transform-origin: right center;
  }

  50% {
    transform: scaleX(0);
    transform-origin: right center;
  }

  50.001% {
    transform-origin: left center;
  }

  50.002% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
    transform-origin: left center;
  }
}

// online convert file

// .offcanvas-nav-wrapper {

//   position: relative;
//   width: 100%;
//   z-index: -1;
// }

.tr__overlay.show {
  transform: none;
  // opacity: 1;
  visibility: visible;
  opacity: 0.9;
  backdrop-filter: blur(14px);
  --webkit-backdrop-filter: blur(14px);
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 50px;

}

.tr__overlay {
  transition: all 0.7s ease-in;
  position: fixed;
  top: 0px;
  left: 0;
  // right: 0;
  // bottom: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  padding: 160px 0px 32px;
  background-color: #121315 !important;
  // animation: 300ms linear backwards expand;
  // mix-blend-mode: lighten;
  // transform: translate(-50%, -50%);
  // transition: width 0.5s ease 0.5s,
  //   height 0.5s ease;
  // width: 0;
  // overflow-y: auto;
  transition: all 0.7s;
  transform: translateY(-10vh);
  opacity: 0;
  scrollbar-width: none;
  visibility: hidden;
}

@keyframes slideIn {
  0% {
    width: 0%;
    // transform: scale(.3);
    opacity: 0;
  }

  100% {
    width: 50%;
    // transform:scale(1);
    opacity: 1;
  }
}

.Main_menu ul li button {
  font-size: 65px;
  line-height: 80px;
  font-weight: 600;
  color: var(--white_clr);
  font-family: var(--AtypDisplay_fontFamily);
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  transition: 0.5s,
    opacity 2s ease;
}

.Main_menu ul li button,
.Main_menu ul li button:after {
  display: block;
  // font-size: 65px;
  // color: var(--white_clr);
  white-space: pre;
  position: relative;
}

.Main_menu ul li button:after {
  content: attr(data-text);
  color: var(--blankaGreen_clr);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  overflow: hidden;
  transition: 2s linear width;
  -webkit-transition: 2s linear width;
  -moz-transition: 2s linear width;
  -o-transition: 2s linear width;
  -ms-transition: 2s linear width;
}

.Main_menu ul li button:hover:after {
  width: 100%;
  cursor: pointer;
}

.Main_menu ul li button:hover {
  transform: translateX(24px);
  transition: all 0.5s ease;
}


// .WWA_Item_leftAnimation:not(:hover) .about_WWA_InsiteContent::before
// .Main_menu ul li {
//   margin-bottom: 24px;
// }

.Main_menu ul li button:hover .navigation__dot {
  visibility: visible;
  opacity: 1;
}

header .menToggle {
  background: hsla(222, 6%, 67%, 0.2);
  position: relative;
  display: flex;
  height: 44px;
  width: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

.navigation__dot {
  transition: all 0.6s ease-in-ease-out;
  visibility: hidden;
  position: absolute;
  left: -2.5rem;
  top: 50%;
  margin-top: -8px;
  height: 1rem;
  width: 1rem;
  --tw-translate-y: -50%;
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  border-radius: 9999px;
  background-color: var(--limeMist_clr);
  // opacity: 0;
}

.navigation__dot span {
  font-size: 5rem;
  line-height: 4.125rem;
  background: linear-gradient(90deg, #c5fcfc 50%, #252525 0);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  background-position-x: 100%;
  transition: all 1s ease;
  display: inline-block;
  padding-top: 0.25rem;
  text-align: center;
  color: #e0eeee;
}

// .Main_menu ul li button:not( .hover) {
//   background-color: red;
// }

.squareToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  // gap: 2px;
  // width: 30px;
  // height: 30px;
  // flex-wrap: wrap;
  // transform: rotate(134deg);
  // -webkit-animation: fa-spin 2s linear infinite;
  //   animation: fa-spin 2s linear infinite
  position: relative;
  border-radius: 50%;
  width: 44px;
  height: 44px;
}

.squareToggle:hover {
  box-shadow: 0px 0px 32px 4px #aeff4899;
  background: #2323277a;
  border: 1px solid #2323277a;
  animation: rotateAndSpin 3s linear infinite, fa-spin 3s linear infinite;
}

// .squareToggle::before{
//   content: "";
//     background: var(--limeMist_clr);
//     filter: blur(100px);
//     width: 160px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     z-index: -1;
//     opacity: 0.6;
//     height: 160px;
//     border-radius: 50%;
//     transform: translate(-50%, -50%);
// }

// .squareToggle span {
//   width: 12px;
//   height: 12px;
//   display: inline-block;
// }

// .squareToggle span:first-child {
//   border: 2px solid #92d43f;
// }

// .squareToggle span:nth-child(2) {
//   border: 2px solid #92d43f;
// }

// .squareToggle span:nth-child(3) {
//   border: 2px solid #777777;
// }

// .squareToggle span:nth-child(4) {
//   border: 2px solid #777777;
// }

@-webkit-keyframes fa-spin {
  // 0% {
  //   -webkit-transform: rotate(0deg);
  //   transform: rotate(0deg)
  // }

  // to {
  //   -webkit-transform: rotate(1turn);
  //   transform: rotate(1turn)
  // }
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.tr__navigation .tr__navigation__dot {
  transition: all 0.75s ease;
  visibility: hidden;
  position: absolute;
  left: -2.5rem;
  top: 50%;
  margin-top: -8px;
  height: 1rem;
  width: 1rem;
  --tw-translate-y: -50%;
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 9999px;
  background-color: #c5fcfc;
  opacity: 0;
}

.tr__light__theme .tr__navigation .tr__navigation__dot {
  background: #3f4144;
}

.tr__navigation .tr__navigation__active .tr__navigation__dot {
  visibility: visible;
  left: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.tr__navigation.tr__navigation__on__hover h1 {
  color: transparent;
  background-position-x: 100%;
}

.tr__navigation.tr__navigation__on__hover:hover h1 {
  background-position-x: 0;
  padding-left: 2.5rem;
}

.tr__navigation.tr__navigation__on__hover:hover .tr__navigation__dot {
  visibility: visible;
  left: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

// thoughts_sec

.commonPage_bnr {
  position: relative;
}

.commonPage_bnr::before {
  content: '';
  position: absolute;
}

.thought_bnr {
  padding: 120px 0px;
}

.commonPage_bnr.thought_bnr::before {
  background: url('./assets/images/thoughts_textborder.png') no-repeat;
  width: 1059px;
  height: 276px;
  right: 40px;
  top: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.projects_bnr {
  padding: 120px 0px;
}

.commonPage_bnr.projects_bnr::before {
  background: url('./assets/images/Projects_textborder.png') no-repeat;
  width: 1059px;
  height: 276px;
  right: 0px;
  // transform: translate(-50%, -50%);
  top: 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.commonPage_bnr.careers_bnr::before {
  background: url('./assets/images/join_our_teamTextborder.png') no-repeat;
  width: 1059px;
  height: 300px;
  right: 60px;
  // transform: translate(-50%, -50%);
  top: 84px;
  background-position: right;
  background-repeat: no-repeat;
}

.careers_bnr {
  padding: 120px 0px;
}

.commonPage_bnr.contactUs_bnr::before {
  background: url('./assets/images/connectTextborder.png') no-repeat;
  width: 967px;
  height: 300px;
  right: 0px;
  top: 90px;
  background-position: center top;
  background-repeat: no-repeat;
}

.aboutUs_bnr {
  position: relative;
  padding: 120px 0px;
}

.contactUs_bnr {
  position: relative;
  padding: 120px 0px;
}

.commonPage_bnr.aboutUs_bnr::before {
  background: url('./assets/images/aboutTextBorder.png') no-repeat;
  width: 1059px;
  height: 276px;
  right: 40px;
  // transform: translate(-50%, -50%);
  top: 0px;
  background-position: right 100px;
  background-repeat: no-repeat;
}

.weHandledSec {
  position: relative;
  padding-bottom: 160px;
}

.weHandledSec::before {
  content: "";
  background: url('./assets/images/weHandledTextborder.png') no-repeat;
  width: 930px;
  height: 350px;
  right: -50px;
  position: absolute;
  // transform: translate(-50%, -50%);
  top: 0px;
  top: -100px;
  background-position: right;
  background-repeat: no-repeat;
}

.commonPage_bnr.services_bnr::before {
  background: url('./assets/images/whatWeDoPageTextBorder.png') no-repeat;
  width: 100%;
  height: 350px;
  right: 50px;
  top: 100px;
  background-position: top right;
  background-repeat: no-repeat;
}

.ourStory_bnr {
  position: relative;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px 100px;
}

.ourStory_bnr::before {
  content: '';
  background: url('./assets/images/ourStoryTextborder.png') no-repeat;
  width: 1059px;
  height: 300px;
  left: 50px;
  position: absolute;
  top: 0px;
  background-position: left;
  background-repeat: no-repeat;
  z-index: -1;
}

.ourClients_sec {
  position: relative;
}

.ourClients_sec::before {
  content: '';
  background: url('./assets/images/ourClientstextborder.png') no-repeat;
  width: 939px;
  height: 378px;
  left: 50%;
  position: absolute;
  top: 0px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  transform: translate(-50%, 60px);
}

.GroupSearchinput {
  padding-bottom: 60px;
}

.GroupSearchinput input {
  background: #ffffff;
  border-right: unset !important;
  font-family: var(--Syne_fontFamily);
  height: 60px;
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
  color: var(--white_clr);
  border-color: transparent;
  padding: 0px 16px;
  outline: unset;
  border-bottom: 2px solid #232327;
  background: transparent;
}

.GroupSearchinput input::placeholder {
  color: #777777;
}

.GroupSearchinput input:focus {
  border: unset;
  outline: unset;
  border-bottom: 2px solid #232327 !important;
  box-shadow: unset;
}

.GroupSearchinput input::placeholder {
  color: #777777;
}

.GroupSearchinput {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  // -ms-flex-wrap: wrap;
  // flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.GroupSearchinput .GroupSearchinput_text {
  background-color: transparent;
  border-left: unset !important;
  border-color: transparent;
  border-bottom: 2px solid #232327;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 24px 0px 0px;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  height: 60px;
}

.GroupSearchinput input::placeholder {
  color: #9aa6ac;
}

.latestBlog {
  padding-bottom: 80px;
}

.blogMain_img {
  position: relative;
  // z-index: 1;
  margin-bottom: 24px;
}

.blogMain_img img {
  border-radius: 30px;
  width: 100%;
  height: 652px;
  object-fit: cover;
}

// .blogMain_img::after {
//   content: '';
//   background: #141414;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   opacity: 0.7;
// }

.limeMistBorderbtn {
  border-radius: 30px;
  height: 50px;
  padding: 0px 34px;
  border: 1px solid var(--limeMist_clr);
  background: transparent;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  font-family: var(--Syne_fontFamily);
  color: var(--limeMist_clr);
  position: relative;
  display: inline-block;
  transition: 0.8s all ease;
  overflow: hidden;
}

.limeMistBorderbtn::before {
  background: var(--limeMist_clr);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
  width: 100%;
  height: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.limeMistBorderbtn:hover {
  background-color: var(--limeMist_clr);
  border-color: var(--limeMist_clr);
  color: var(--verifiedBlack);
  font-weight: 600;
}

.limeMistBorderbtn:hover::before {
  height: 380%;
}

.limeMistBorderbtn:hover img {
  filter: var(--verifiedBlackFilter);
}

.smallbutton {
  background: transparent;
  border: 1px solid var(--limeMist_clr);
  border-radius: 90px;
  height: 40px;
  font-family: var(--Syne_fontFamily);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  // padding: 0px 44px;
  padding: 0px 24px;
  color: var(--limeMist_clr);
  position: relative;
  display: inline-block;
  transition: 0.8s all ease;
  overflow: hidden;
}

.smallbutton::before {
  background: var(--limeMist_clr);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
  width: 100%;
  height: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.smallbutton:hover {
  background-color: var(--limeMist_clr);
  border-color: var(--limeMist_clr);
  color: var(--verifiedBlack);
  font-weight: 600;
}

.smallbutton:hover::before {
  height: 380%;
}

.limeMistFullClrbtn {
  border-radius: 30px;
  height: 50px;
  padding: 0px 34px;
  border: 1px solid var(--limeMist_clr);
  background: var(--limeMist_clr);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  font-family: var(--Syne_fontFamily);
  color: var(--verifiedBlack);
  transition: all 250ms;
  position: relative;
  transition: 0.5s;
  transition-property: box-shadow;
}

.limeMistFullClrbtn:hover {
  box-shadow: 0px 0px 5px #aeff4899, 0px 0px 25px #aeff4899,
    0px 0px 50px #aeff4899, 0px 0px 100px #aeff4899;
}



.cursedBlackBtn {
  background: var(--cursedBlack_clr);
  color: var(--limeMist_clr);
  border-radius: 30px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--Syne_fontFamily);
  height: 64px;
  padding: 0px 34px;
  position: relative;
  display: inline-block;
  transition: 0.8s all ease;
  overflow: hidden;
}

.cursedBlackBtn::before {
  background: var(--cursedBlack_clr);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.6s ease;
  width: 100%;
  height: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.cursedBlackBtn:hover {
  background-color: var(--limeMist_clr);
  color: var(--verifiedBlack);
}

.cursedBlackBtn:hover::before {
  height: 380%;
  position: relative;
  z-index: 999;
  color: var(--limeMist_clr);
}

.blogImagepart {
  padding-bottom: 60px;
}

.blogDetailPage {
  padding: 80px 0px;
}

.blogItemImage {
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;

  border-radius: 20px;
}

.blogItemImage img {
  object-fit: cover;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  height: 400px;
}

.blogItemImage:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

// .blogItemImage::after {
//   content: '';
//   background: #111111;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   opacity: 0.4;
//   transition: 0.5s;
// }

.mailtobtn {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 42px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--drWhite_clr);
}

.mailtobtn img {
  display: none;
}

.mailtobtn:hover img {
  display: block;
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
  float: right;
}

/* right bounce */
@-webkit-keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}

@-moz-keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-30px);
  }

  60% {
    transform: translateX(-15px);
  }
}

@keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}

/* /right bounce */

.verifiedBlackBottomborder {
  border-bottom: 1px solid var(--verifiedBlack);
}

footer {
  padding: 60px 0px 140px;
}

// .footerProjectFrame{
//   padding: 16px;
//   background: #2323272b;
//   border-radius: 10px;
// }

.footerProjectFrame ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.footerProjectFrame ul li {
  margin-bottom: 16px;
}

.footerProjectFrame ul li button {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  font-family: var(--AtypDisplay_fontFamily);
  color: var(--white_clr);
  transition: 0.5s;
}

.footerProjectFrame ul li button img {
  display: none;
}

.footerProjectFrame ul li button:hover {
  color: var(--blankaGreen_clr);
  transform: translateX(12px);
  transition: all 0.5s ease;
}

.copyRightSec {
  background: var(--verifiedBlack);
  padding: 12px 0px;
}

.TwitterWidth_243px {
  width: 243px;
}

.policyCondition li button:hover {
  color: var(--limeMist_clr);
}

.projectsTab ul {
  display: flex;
  align-items: center;
  gap: 40px 16px;
  flex-wrap: wrap;
  padding-bottom: 150px;
  border-bottom: unset !important;
  margin: 0px !important;
}

.projectsTab ul li button {
  background: transparent;
  border: 1px solid var(--SteelWool_clr) !important;
  border-radius: 40px !important;
  color: var(--SteelWool_clr);
  height: 48px;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 400;
  padding: 0px 34px;
  // transition: mymove .3s ease;
  display: block;
  width: auto;
  position: relative;

}

.projectsTab ul li button:hover {
  border: 1px solid var(--limeMist_clr) !important;
  color: var(--limeMist_clr) !important;
  background-color: transparent !important;
  // transition: padding 0.6s ease-in-out, border 0.6s ease-in-out;
  // padding: 0px 60px;
}


.projectsTab ul li button.active {
  border: 1px solid var(--limeMist_clr) !important;
  color: var(--limeMist_clr) !important;
  background-color: transparent !important;
  padding: 0px 96px;
  transition: padding 0.6s ease-in-out, border 0.6s ease-in-out;
  // transition: width 0.6s ease-in-out;
  // transition: forwards .5s ease-in;
  // animation: mymove 0.6s ease-in-out;
}

// @keyframes mymove{
//   0% {
//       width: 0;
//       white-space: nowrap;
//     }
//     100% {
//       width: 460px;
//     }
// }



// .projectsTab ul li:first-child button {
//   width: 500px;
// }

.projectsFullviewleft {
  padding-bottom: 100px;
}

.projectsMediumviewRight {
  padding-bottom: 100px;
}

.projectsFullviewRight {
  padding-bottom: 100px;
}

.projectsSmallview_spacebetween {
  padding-bottom: 100px;
}

.projectsList .slick-slide {
  padding: 0px 16px;
  /* Adjust the margin as needed */
}

.projectsList {
  // padding-bottom: 100px;
  // padding-right: 40px;
  padding-right: 0px;
}

.projectsListItem {
  position: relative;
}

.projectsListItem img {
  min-height: 354px;
  height: 354px;
  max-height: 354px;
  width: 100%;
  border-radius: 0px;
  transition: transform 2s;
  border-radius: 30px;
}

.projectsListItem_skeletonImage {
  min-height: 354px;
  height: 354px;
  max-height: 354px;
  width: 100%;
}

.projectsList .slick-track {
  padding: 200px 0px;

}

.projectsList .slick-center.slick-current {
  z-index: 9999;
  position: relative;
  opacity: unset !important;
  // transform: scale(2, 1.7);
  transform: scale(2.3 , 1.9);
  transition: transform 0.6s ease-in-out;
}


.projectsList .slick-slide {
  opacity: 0.3;
  // transition: opacity 1s;
}
.projectsList.projectLengthOpacity .slick-slide{
  opacity: unset !important;
}
.projectsList .slick-center.slick-current img {
  border-radius: 30px;
  object-fit: cover;

}

// .projectsList .slick-slide:nth-child(odd) .projectsListItem:hover img {
//   transform: scale(1.3) translate(-10%, 10%);
// }

.projectsListItem {
  position: relative;
}


// .sliderNextPosition {
//   position: absolute;
//   // bottom: 100px;
//   top: 0px !important;
//   right: 100px;
// }

// .sliderPrevPosition {
//   position: absolute;
//   // bottom: 100px;
//   left: 100px;
//   top: 0px !important;
// }

// .projectsListItem:hover img {
//   transform: scale(1.3) translate(10%, 10%);
//   // border-radius: 30px;
//   z-index: 9999;
//   position: relative;
// }

.projectsList .slick-prev:before {
  content: '' !important;
  background: url("./assets/images/circleArrow_left.svg");
  // transform: rotate(-180deg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  width: 20px;
  height: 18px;
  display: block;
  // position: absolute;
}

.projectsList .slick-next:before {
  content: '' !important;
  background: url('./assets/images/circleArrow_right.svg');
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  width: 20px;
  height: 18px;
  display: block;
  // position: absolute;
}

.projectsList .slick-prev {
  left: -40px;
  // bottom: 100px;
  top: 50% !important;
  width: 34px;
  background: var(--limeMist_clr);
  border-radius: 50%;
  height: 34px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition-property: box-shadow;
}

.projectsList .slick-next {
  right: -40px;
  // bottom: 100px;
  top: 50% !important;
  border-radius: 50%;
  background: var(--limeMist_clr);
  width: 34px;
  height: 34px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition-property: box-shadow;
}

.projectsList .slick-prev:hover,
.projectsList .slick-prev:focus,
.projectsList .slick-next:hover,
.projectsList .slick-next:focus {
  color: transparent;
  outline: none;
  background: var(--limeMist_clr);
  box-shadow: 0px 0px 5px #aeff4899, 0px 0px 25px #aeff4899,
    0px 0px 50px #aeff4899, 0px 0px 100px #aeff4899;

}


// .projectImg{
//  position: relative;
// }
// .projectImg::before{
//   content: "";
//   width: 0;
//   top: -20px;
//   left : -20px;
//   border-radius : 33.18px;
//   position: absolute;
//   z-index: -1;
//   background-color: #232327;

// }

// .projectImg::after {
//     content: "";
//     width: 0;
//       top: 20px;
//       left: 20px;
//       border-radius: 33.18px;
//       position: absolute;
//       z-index: -1;
//       opacity: 0.8;
//       background-color: #232327;
// }
// .projectImg:hover:before ,
// .projectImg:hover:after{
// width: 100%;
// height: 100%;
// }

.projectImg {
  position: relative;
}

.projectImg::before,
.projectImg::after {
  content: '';
  width: 0;
  height: 0;
  border-radius: 33.18px;
  position: absolute;
  z-index: -1;
  background-color: #232327;
  transition: all 2s ease;
  /* Smooth transition on hover */
}

.projectImg::before {
  top: -20px;
  left: -20px;
}

.projectImg_skeleton {
  height: 400px;
}

.projectImg::after {
  top: 20px;
  right: -20px;
  /* Changed to right */
  opacity: 0.8;
}

.projectImg:hover::before {
  width: calc(100% + -20px);
  height: calc(100% + -20px);
}

.projectImg:hover::after {
  width: calc(100% + -10px);
  height: calc(100% + 10px);
}
.project_icon img{
    width: 38px;
    height: 38px;
    object-fit: cover;
    max-width: unset;
}
.projectTitlePart{
    display: flex;
    align-items: baseline;
    gap: 30px;
}

.teamcredits_sec {
  padding: 0px 0px 100px;
  border-bottom: 1px solid #232327;
}

.teamcredits_sec h3 {
  font-size: 48px;
  line-height: 1.5;
  font-style: italic;
  font-weight: 400;
  margin: 0px;
  font-family: var(--AtypDisplay_fontFamily);
  color: var(--white_clr);
}

.teamcredits_sec {
  overflow: hidden;
}

.teamcredits_scrollani {
  border-top: 1px solid var(--SteelWool_clr);
  border-bottom: 1px solid var(--SteelWool_clr);
  padding: 24px 0px;
}

.teamcredits_sec ul {
  display: flex;
  list-style: none;
  gap: 24px;

  scroll-snap-type: x mandatory;
  -webkit-animation: slide-har 20s linear infinite;
  animation: slide-har 20s linear infinite;
  white-space: nowrap;
}

.teamcredits_sec ul li {
  display: flex;
  gap: 10px;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.otherproject_Sec {
  padding: 80px 0px;
}

.lightGreenEffectBtn {
  border: 1px solid var(--limeMist_clr);
  color: var(--verifiedBlack);
  background: var(--limeMist_clr);
  font-family: var(--Syne_fontFamily);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  height: 60px;
  padding: 0px 34px;
  // width: 400px;
  border-radius: 90px;
  transition: 0.5s;
  transition-property: box-shadow;
  // box-shadow: 0px 0px 32px 4px #aeff4899;
}

.lightGreenEffectBtn:hover {
  box-shadow: 0px 0px 5px #aeff4899, 0px 0px 25px #aeff4899,
    0px 0px 50px #aeff4899, 0px 0px 100px #aeff4899;
}

.OtherItemImage {
  position: relative;
  z-index: 1;
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 20px;
}

.OtherItemImage:hover img,
.otherImage_skeleton:hover {
  transform: scale(1.1);
}

.OtherItemImage img,
.otherImage_skeleton {
  transition: 0.5s;
  width: 100%;
  height: 400px;
  object-fit: cover;
}

// .OtherItemImage::after {
//   content: '';
//   background: #111111b2;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }

.otherproject_Sec {
  padding-bottom: 80px;
}

.want_to_workwithus {
  padding-bottom: 80px;
}

// .Home_bnr{
//   position: absolute;
//   top: 0;
// }
// .Home_bnr .carousel-item {
//   // height: 690px;
//   height: 750px;
// }

// .Home_bnr .carousel {
//   z-index: -1;
// }

// .Home_bnr .carousel-item img.bnrImage {
//   width: 100%;
//   position: relative;
//   height: 100%;
// }

@-webkit-keyframes zoomSlow {
  0% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.3) rotate(0.1deg);
    -webkit-transform: scale(1.3) rotate(0.1deg);
    transform: scale(1.3) rotate(0.1deg);
  }

  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes zoomSlow {
  0% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.3) rotate(0.1deg);
    -webkit-transform: scale(1.3) rotate(0.1deg);
    transform: scale(1.3) rotate(0.1deg);
  }

  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes zoomSlow {
  0% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.3) rotate(0.1deg);
    -webkit-transform: scale(1.3) rotate(0.1deg);
    transform: scale(1.3) rotate(0.1deg);
  }

  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

// .bnr_Overlay {
//   position: relative;
//   webkit-animation: zoomSlow 30s infinite linear;
//   -moz-animation: zoomSlow 30s infinite linear;
//   animation: zoomSlow 30s infinite linear;
//   display: flex;
//   background-repeat: no-repeat;
// }

// .Home_bnr .carousel-item .bnr_Overlay::after {
//   content: '';
//   background: #000000cc;
//   position: absolute;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   height: 100%;
// }

// .Home_bnr .carousel-indicators {
//   position: absolute;
//   right: 0 !important;
//   left: 40px !important;
//   display: unset !important;
//   margin: 0px !important;
// }

// .Home_bnr .carousel-indicators [data-bs-target] {
//   box-sizing: content-box;
//   flex: 0 1 auto;
//   width: 50px !important;
//   height: 1px !important;
//   padding: 0;
//   margin-right: 3px;
//   margin-left: 3px;
//   margin-bottom: 40px !important;
//   text-indent: -999px;
//   cursor: pointer;
//   background-color: #fafafa !important;
// }

// .Home_bnr .carousel-indicators .active {
//   opacity: unset !important;
//   background-color: var(--blankaGreen_clr) !important;
// }

// .Home_bnr .carousel-indicators button:hover {
//   opacity: unset !important;
//   background-color: var(--blankaGreen_clr) !important;
// }

// .Home_bnr .carousel-caption {
//   // z-index: 1;
//   // right: 60px;
//   // left: 40px;
//   left: 0;
//   right: 0;
//   padding: 0px;
//   text-align: unset !important;
//   top: 120px;
//   height: calc(100% - 120px);
// }

// .carousel-caption .row {
//   height: 100%;
// }

// .Home_bnr .container {
//   height: 100%;
// }

.rightHeightBnr {
  display: flex;
  justify-content: end;
  flex-direction: column;
}

// .Home_bnr .carouselCaption {
//   position: absolute;
//   left: 0;
//   right: 60px;
//   left: 40px;
//   padding: 0px;
//   text-align: unset !important;
//   top: 130px;
// }

.maxheightLeftcontent {
  // height: calc(100% - 80px);
  height: 100%;
}

.Home_bnr .bnrMain-content h1 {
  font-family: var(--AtypDisplay_fontFamily) !important;
  // line-height: 1.5;
  line-height: 100px;
  font-weight: 400;
  color: var(--drWhite_clr);
  font-size: 120px;
  font-weight: 600;
  margin: 0px;
  text-align: right;
}

.Home_bnr .bnrMain-content h2 {
  font-family: var(--AtypDisplay_fontFamily) !important;
  line-height: 100px;
  font-weight: 600;
  color: var(--blankaGreen_clr);
  font-size: 120px;
  margin: 0px 0px 24px;
  text-align: right;
}

.Home_bnr .bnrMain-content p {
  font-family: var(--Syne_fontFamily) !important;
  line-height: 1.5;
  font-weight: 500;
  // color: var(--cerebralGrey_clr);
  color: var(--white_clr);
  font-size: 24px;
  margin: 0px;
  text-align: right;
}

.textDirection a {
  transform: rotate(-90deg);
  transform-origin: left center;
  white-space: nowrap;
  display: flex;
}

.whoWeare_sec {
  margin: 200px 0px;
  position: relative;
}

.whoWeare_sec::before {
  content: '';
  background: url('./assets/images/whoWeareTextborder.png');
  background-repeat: no-repeat;
  background-position: top;
  width: 580px;
  height: 370px;
  position: absolute;
  left: 20px;
  // right: 0;
  top: 0px;
  // bottom: 0;
  z-index: -1;
}

.whoWeare_sec::after {
  content: '';
  background: var(--limeMist_clr);
  filter: blur(100px);
  width: 200px;
  position: absolute;
  top: 70px;
  z-index: -1;
  opacity: 0.6;
  height: 200px;
  left: 30%;
}

.size_90 {
  font-size: 90px !important;
}

.whoWeAreItem {
  background: linear-gradient(136deg,
      var(--verifiedBlack) 9.59%,
      #141414 98.59%);
  border: 1px solid #434343;
  justify-content: space-between;
  padding: 24px;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  gap: 30px;
  // -webkit-transition: border-color background box-shadow .4s ease-in-out;
  // -moz-transition: border-color background box-shadow .4s ease-in-out;
  // -o-transition: border-color background box-shadow .4s ease-in-out;
  // -ms-transition: border-color background box-shadow .4s ease-in-out;
  // transition: border-color background box-shadow .4s ease-in-out;
}

.whoWeAreItem:hover {
  background: var(--limeMist_clr);
  border-color: var(--limeMist_clr);
  box-shadow: 0px 0px 30px rgba(174, 255, 72, 0.6);
  // transition: all 2s;
}

.whoWeAreItem:hover h3 {
  color: var(--verifiedBlack);
}

.whoWeAreItem:hover p {
  color: var(--verifiedBlack);
}

.whoWeAreItem h3 {
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 64px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0px;
  color: var(--whitesmoke_clr);
}

.whoWeAreItem p {
  font-family: var(--Syne_fontFamily);
  font-size: 32px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: var(--limeMist_clr);
  text-align: right;
}

.whoWeareFontsizeIncrease {
  // transform: translate(-18%, -16%);
  position: relative;
  z-index: -1;
  padding-top: 30px;
}

.whoWeareFontsizeIncrease p {
  color: #29292a;
  font-family: var(--Syne_fontFamily);
  font-weight: 400;
  font-size: 44px;
}

.whoWeareFontsizeIncrease h4 {
  font-size: 320px;
  font-weight: 400;
  font-family: var(--Syne_fontFamily);
  line-height: 280px;
  margin: 0px;
  color: #29292a;
}

.whatWedo_sec {
  // padding: 100px 0px;
  position: relative;
}

.whatWedo_sec::before {
  content: '';
  background: url('./assets/images/whatWrDoTextborder.png');
  background-repeat: no-repeat;
  background-position: top;
  width: 673px;
  height: 400px;
  position: absolute;
  left: 40px;
  // right: 0;
  top: 0;
  // bottom: 0;
  z-index: -1;
}

.VerticalStepsWrapper {
  padding: 100px 0px 0px;
}

.VerticalSteps {
  display: flex;
  // gap: 16px;
}

.centerStroke {
  border-left: 2px solid var(--verifiedBlack);
  // margin: 0px 20px;
  position: relative;
}

.VerticalSteps>div:first-child {
  margin-left: auto;
  display: block;
  // padding: 0px 0px 60px 74px;
}

.VerticalSteps>div:nth-child(3) {
  margin-left: auto;
  display: block;
  text-align: right;
}

.VerticalStepsWrapper>div:first-child>div:first-child>div {
  padding: 0px 0px 60px 74px;
  border-bottom: 2px solid var(--verifiedBlack);
}

.VerticalStepsWrapper>div:first-child>div:nth-child(3)>div {
  border-bottom: 2px solid var(--verifiedBlack);
  // padding: 100px 74px 60px 0px;
  padding: 0px 74px 60px 0px;
}

.VerticalStepsWrapper>div:nth-child(2)>div:first-child>div {
  // padding: 0px 0px 60px 74px;
  padding: 60px 0px 60px 74px;
  // border-bottom: 2px solid var(--verifiedBlack);
}

.VerticalStepsWrapper>div:nth-child(2)>div:nth-child(3)>div{
  border-bottom: 2px solid var(--verifiedBlack);
  padding: 50px 74px 90px 0px;
}

.VerticalSteps>div:nth-child(2) {
  width: 80px;
  flex: 0;
}

.VerticalSteps>div {
  flex: 1 1 0;
}

.wwd_FirstImgSize img {
  // width: 418px;
  // height: 590px;
  width: 352px;
  height: 525px;
  object-fit: cover;
}

.wwd_SecondImgSize img {
  width: 352px;
  height: 525px;
}

.wwd_ThirdImgSize img {
  // width: 312px;
  //   height: 469px;
  width: 352px;
  height: 525px;
}

.wwd_FourthImgSize img {
  // width: 418px;
  //   height: 916px;
  width: 352px;
  height: 525px;
}

.bg_paragraph {
  position: relative;
  margin: 120px 0px 250px;
  padding: 100px 0px;
  z-index: 1;
  background: url('./assets/images/alamathi_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg_paragraph::before {
  content: '';
  // background: url('./assets/images/alamathi_bg.png');
  background-repeat: no-repeat;
  // background-position: right;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: linear-gradient(to top, #000, transparent);
  transition: .5s;
  opacity: 0.3;
}


.bg_paragraph p {
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 96px;
  font-weight: 300;
  line-height: 100px;
  color: var(--drWhite_clr);
}

.bg_paragraph p span {
  font-weight: 600;
  color: var(--cerebralGrey_clr);
}

.projects_home {
  position: relative;
}

.projects_home::before {
  content: '';
  background: url('./assets/images/projects_homeTextborder.png');
  background-repeat: no-repeat;
  background-position: top;
  width: 982px;
  height: 149px;
  position: absolute;
  left: 10px;
  right: 0;
  top: -30px;
  // bottom: 0;
  z-index: -1;
}

.homeProject_Item {
  position: relative;
}

.homeProject_Item::before {
  content: '';
  background-image: linear-gradient(to bottom, #141414, transparent);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 200px;
  opacity: 0.7;
}

.homeProject_Item img {
  width: 100%;
  height: 100vh;
  padding-bottom: 40px;
}

.h2-9{
  background-position-x: "100%";
}

.homeProject_Wrapper {
  padding: 0px 0px 40px;
  position: relative;
  // bottom: 100px;
  // margin-bottom: 100px;
}

.homeProject_InnerContent {
  // z-index: 9999;
  position: absolute;
  top: 0;
  // padding: 0px 104px;
}

.homeProject_InnerContent h2 {
  font-family: var(--Syne_fontFamily);
  font-size: 160px;
  font-weight: 400;
  color: var(--drWhite_clr);
  margin: 0px;
}

.homeProject_InnerContent h5 {
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 32px;
  font-weight: 400;
  color: var(--drWhite_clr);
  margin: 0px;
}

.homeProject_InnerContent p {
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 16px;
  font-weight: 500;
  color: var(--drWhite_clr);
  margin: 0px;
}

.testimonials_sec {
  position: relative;
  margin: 100px 0px 100px;
}

.testimonials_sec::before {
  content: '';
  background: url('./assets/images/testimonialsTextborder.png');
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 150px;
  position: absolute;
  left: -60px;
  right: 0;
  top: -30px;
  // bottom: 0;
  z-index: -1;
}

.testimonials_sec .testimonial_Item {
  border: 1px solid #828282;
  background: var(--verifiedBlack);
  padding: 30px;
  border-radius: 40px;
  transition: border 0.5s ease;
  min-height: 500px;
  max-height: 500px;
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  transition: border 0.5s ease;
  outline: unset !important;
}

.testimonials_sec .testimonial_Item:hover {
  border: 1px solid var(--limeMist_clr);
}
 .testimonials_sec .slick-center.slick-slide {
  opacity: 1;
  transition: all 0.6s ease-in-out;
 }

// .testimonials_sec .slick-center+.slick-slide {
//   transition: all 0.6s ease-in-out;
//   margin-top: -45px;
// }

.testimonials_sec .slick-center {
  transition: all 0.6s ease-in-out;
  margin-top: -45px;
}

// .testimonials_sec .slick-center {
//   margin: 0;
// }

.testimonials_sec .slick-slide {
  padding: 50px 16px 0px;
  transition: all 0.6s ease-in-out;
}

.testimonial_Item p {
  color: var(--drWhite_clr);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--Syne_fontFamily);
}

.testimonial_profile img {
  width: 70px;
}

.testimonial_Item .commaIcon {
  padding-bottom: 30px;
}

.testimonial_Item:hover .commaIcon img {
  filter: var(--limeMistFilter);
}

// .testimonials_sec .slick-center+.slick-slide .testimonial_Item .commaIcon img {
//   filter: var(--limeMistFilter);
// }

.testimonials_sec .slick-center .testimonial_Item .commaIcon img {
  filter: var(--limeMistFilter);
}

// .testimonials_sec .slick-center+.slick-slide {
//   opacity: unset;
//   transition: all 0.2 ease-in-out;
// }
.testimonials_sec .slick-center {
  opacity: 1;
  transition: all 0.2 ease-in-out;
}
.testimonials_sec .slick-arrow.slick-prev {
  display: none !important;
}

.testimonials_sec .slick-slide:hover {
  opacity: 1;
}

.testimonials_sec .slick-slide {
  opacity: 0.3;
  transition: opacity 1s;
  outline: unset;
}

.testimonial_Item:hover {
  opacity: unset;
}





// background: linear-gradient(268.93deg, rgba(18, 19, 21, 0) 11.37%, #121315 99.08%);

.latestUpdate_sec {
  padding: 100px 0px;
  position: relative;
}

// .latestUpdate_sec::before {
//   content: "";
//   background: var(--limeMist_clr);
//   filter: blur(100px);
//   width: 300px;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   z-index: -1;
//   opacity: 0.6;
//   height: 300px;
//   border-radius: 50%;
//   transform: translate(-50%, -50%);
// }

// .latestUpdate_sec::after {
//   content: "";
//   background: url('./assets/images/limeMistdot.png');
//   background-position: center;
//   background-size: contain;
//   width: 300px;
//   position: absolute;
//   bottom: -100px;
//   left: 50%;
//   z-index: -1;
//   height: 300px;
//   border-radius: 50%;
//   transform: translate(-50%, -50%);
// }

.welcomrToOurTeam_sec {
  padding: 100px 0px;
}

.careerItem {
  border: 1px solid var(--blackSuede_clr);
  background: var(--verifiedBlack);
  border-radius: 30px;
  padding: 24px;
  height: 410px;
  min-height: 100%;
  transition: 0.5s;
  position: relative;
  cursor: pointer;
}

.careerItem:hover {
  transform: translateY(-7px);
  // border: 2px solid var(--blankaGreen_clr);
}

.careerItem_icon {
  background: #121315;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.careerItem_icon img{
  width: 60px;
}

.careerItem:hover button {
  border: 1px solid var(--limeMist_clr);
  color: var(--limeMist_clr);
}

.careerItem button {
  background: transparent;
  border: 1px solid var(--SteelWool_clr);
  border-radius: 90px;
  height: 40px;
  font-family: var(--Syne_fontFamily);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 0px 34px;
  color: var(--SteelWool_clr);
  position: relative;
  display: inline-block;
  transition: 0.8s all ease;
  overflow: hidden;
}

.careerItem button::before {
  background: var(--limeMist_clr);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
  width: 100%;
  height: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.careerItem button:hover {
  background-color: var(--limeMist_clr);
  border-color: var(--limeMist_clr);
  color: var(--verifiedBlack) !important;
  font-weight: 600;
}

.careerItem button:hover::before {
  height: 380%;
  font-weight: 600;
}

.careerItem button:hover img {
  filter: var(--verifiedBlackFilter);
}

.rolesTab {
  margin-bottom: 34px;
}

.rolesTab ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

ul.RequirementList {
  padding-left: 0px;
}

.rolesTab ul li button {
  border: 0.5px solid var(--limeMist_clr);
  height: 50px;
  cursor: pointer !important;
  padding: 0px 20px;
  color: var(--limeMist_clr);
  font-family: var(--Syne_fontFamily);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  border-radius: 10px;
}

.careerPopup .modal-dialog {
  max-width: 950px !important;
  // overflow: visible;
}

.careerPopup .modal-content {
  background-color: var(--verifiedBlack) !important;
  border: 1px solid #464646 !important;
  border-radius: 30px !important;
  padding: 34px;
  max-height: 80vh;
  /* Adjust as necessary */
  overflow-y: auto;

  // overflow: visible;
  // white-space: nowrap;
  position: relative;
}



.careerPopup .modal-body {
  // max-height: 60vh; /* Adjust as necessary */
  // overflow-y: auto;
}



.careerPopup .modal-header {
  justify-content: space-between;
  border-bottom: unset !important;
  padding: 0px !important;
  margin-bottom: 34px;
}

.careerPopup .modal-body {
  padding: 0px !important;
}

.careerPopup .modal-header h2 {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--AtypDisplay_fontFamily);
  margin: 0px;
  color: var(--white_clr);
}

/* width */
.careerPopup .modal-content::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  border-radius: 10px;

}

.careerPopup .modal-content::-webkit-scrollbar-track {
  // background: var(--verifiedBlack);
  position: relative;
  // border-top-right-radius: 30px;
  // border-bottom-right-radius: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
}

/* Track */
// .careerPopup .modal-content::-webkit-scrollbar-track {
//   background: var(--verifiedBlack) !important;
// }

/* Handle */
.careerPopup .modal-content::-webkit-scrollbar-thumb {
  background: var(--blankaGreen_clr);
  border-radius: 5px;
}

/* Handle on hover */
.careerPopup .modal-content::-webkit-scrollbar-thumb:hover {
  background: var(--blankaGreen_clr);
}

/* custom modal  style end */

.shareViaPopup .modal-header {
  justify-content: space-between;
  border-bottom: unset !important;
  padding: 0px !important;
  margin-bottom: 50px;
}

.shareViaPopup .modal-content {
  background-color: var(--verifiedBlack) !important;
  border: 1px solid var(--verifiedBlack) !important;
  border-radius: 30px !important;
  padding: 34px 34px 50px;
}

.shareViaPopup .modal-body {
  padding: 0px !important;
}

ul.RequirementList {
  list-style-type: square;
  padding-left: 20px;
  margin-bottom: 34px !important;
}

.borderTextarea {
  color: var(--SteelWool_clr);
  font-family: var(--Syne_fontFamily);
  font-size: 16px !important;
  line-height: 1.5 !important;
  font-weight: 400;
  border-bottom: 1px solid #77777780 !important;
  width: 100%;
  padding: 0px 16px !important;
  border: unset;
  background-color: transparent !important;
}

.borderTextarea:focus {
  box-shadow: unset !important;
  border-color: var(--limeMist_clr) !important;
  color: var(--limeMist_clr) !important;
}

.borderTextarea::placeholder {
  color: var(--SteelWool_clr);
  font-family: var(--Syne_fontFamily);
}

.borderInput {
  color: var(--SteelWool_clr);
  font-family: var(--Syne_fontFamily);
  font-size: 16px !important;
  line-height: 1.5 !important;
  font-weight: 400;
  border-bottom: 1px solid #77777780 !important;
  height: 60px;
  width: 100%;
  padding: 0px 16px !important;
  border: unset;
  background-color: transparent !important;
}

.borderInput:focus {
  box-shadow: unset !important;
  border-color: var(--limeMist_clr) !important;
  color: var(--limeMist_clr) !important;
}

.borderInput::placeholder {
  color: var(--SteelWool_clr);
  font-family: var(--Syne_fontFamily);
}

.borderSelectInput {
  color: var(--SteelWool_clr);
  font-family: var(--Syne_fontFamily);
  font-size: 16px !important;
  line-height: 1.5 !important;
  font-weight: 400;
  border-bottom: 1px solid #77777780 !important;
  height: 60px;
  width: 100%;
  padding: 0px 16px !important;
  border: unset;
  background: transparent url('./assets/images/selectDropdown_arrow.svg') no-repeat !important;
  background-size: 20px;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
}

.borderSelectInput:focus {
  box-shadow: unset !important;
  background: var(--verifiedBlack) !important;
  color: var(--limeMist_clr) !important;
}

.borderSelectInput option {
  background: var(--verifiedBlack) !important;
  color: var(--limeMist_clr) !important;
  background: var(--verifiedBlack) !important;
}

.GroupBorderinput input:focus {
  box-shadow: unset !important;
  border-color: var(--limeMist_clr) !important;
  color: var(--limeMist_clr) !important;
}

.GroupBorderinput input {
  color: var(--SteelWool_clr);
  font-family: var(--Syne_fontFamily);
  font-size: 16px !important;
  line-height: 1.5 !important;
  font-weight: 400;
  border-bottom: 1px solid #77777780 !important;
  height: 60px;
  width: 100%;
  padding: 0px 16px !important;
  border: unset;
  background-color: transparent !important;
}

.GroupBorderinput {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  // -ms-flex-wrap: wrap;
  // flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.GroupBorderinput .GroupBorderinput_text {
  font-family: var(--Syne_fontFamily);
  background-color: transparent;
  border: unset;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 8px;
  border-bottom: 1px solid #77777780 !important;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  height: 60px;
  font-weight: 400;
  font-size: 16px;
  color: var(--SteelWool_clr);
}

.GroupBorderinput input::placeholder {
  color: var(--SteelWool_clr);
  font-family: var(--AtypDisplay_fontFamily);
}

.GroupBorderinput .custom-file-upload {
  color: var(--SteelWool_clr) !important;
  font-family: var(--AtypDisplay_fontFamily);
}

.GroupBorderinput:has(input:focus) .GroupBorderinput_text {
  border-color: var(--limeMist_clr) !important;
}

.contactUs_sec {
  padding: 100px 0px;
}

// toggle css
.custom_switch {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding-bottom: 100px;
}

.toggle_switch {
  display: inline-block;
  border-radius: 80px;
  cursor: pointer;
  background: var(--verifiedBlack);
  width: 139px;
  height: 64px;
  position: relative;
  vertical-align: middle;
}

.toggle_switch:before,
.toggle_switch:after {
  content: '';
}

.toggle_switch:before {
  display: block;
  background: #92d43f url('./assets/images/handShake_icon.svg');
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 52px / 2);
  left: 10px;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 0px 20px 0px #dbffadcc;
  transition: transform 0.3s;
}

.toggle_checkbox:checked+.toggle_switch {
  background: var(--verifiedBlack);
}

.toggle_checkbox:checked+.toggle_switch::before {
  background: #92d43f url('./assets/images/fileText_icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  // right: 10px;
  right: 33px;
  left: auto;
  transform: translateX(25px);
  /* Ensure left is reset */
}

// .toggle_checkbox:checked+.toggle_switch:before {
//   right: 10px;
//   margin-left: auto;
// }

.toggle_checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle_checkbox:checked~.toggle-label {
  color: var(--limeMist_clr);
  /* Add your active color variable or a specific color code */
}

.custom_switch span {
  margin: 0px 16px;
  font-family: var(--AtypDisplay_fontFamily);
  position: relative;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.5;
  color: var(--drWhite_clr);
}

// .switch {
//   position: relative;
//   display: block;
//   vertical-align: top;
//   width: 81px;
//   height: 21px;
//   border-radius: 12px;
//   cursor: pointer;
//   box-sizing: content-box;
// }

// .switch-input {
//   position: absolute;
//   top: 0;
//   left: 0;
//   opacity: 0;
//   box-sizing: content-box;
// }

// .switch-label {
//   position: relative;
//   display: block;
//   height: inherit;
//   font-size: 12px;
//   font-weight: 500;
//   line-height: 1.5;
//   background: red;
//   border-radius: inherit;
//   box-sizing: content-box;
// }

// .switch-label:before,
// .switch-label:after {
//   position: absolute;
//   // top: 50%;
//   // margin-top: -.5em;
//   // line-height: 1;
//   -webkit-transition: inherit;
//   -moz-transition: inherit;
//   -o-transition: inherit;
//   transition: inherit;
//   box-sizing: content-box;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
// }

// .switch-label:before {
//   content: attr(data-off);
//   right: 11px;
//   color: #000;
// }

// .switch-label:after {
//   content: attr(data-on);
//   left: 11px;
//   color: var(--white_clr);
//   opacity: 0;
// }

// .switch-input:checked~.switch-label {
//   background: var(--limeMist_clr);
// }

// .switch-input:checked~.switch-label:before {
//   opacity: 0;
// }

// .switch-input:checked~.switch-label:after {
//   opacity: 1;
// }

// .switch-handle {
//   position: absolute;
//   top: 1.5px;
//   left: 2px;
//   width: 18px;
//   height: 18px;
//   background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
//   background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
//   border-radius: 100%;
//   box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
// }

// .switch-handle:before {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   background: linear-gradient(to bottom, #eeeeee, #ffffff);
//   background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
//   border-radius: 6px;
//   box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
// }

// .switch-input:checked~.switch-handle {
//   left: 61px;
// }

// /* Transition
// ========================== */
// .switch-label,
// .switch-handle {
//   transition: All 0.3s ease;
//   -webkit-transition: All 0.3s ease;
//   -moz-transition: All 0.3s ease;
//   -o-transition: All 0.3s ease;
// }

.aboutUs_intro {
  padding: 100px 0px;
}

.centerActive {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #121315;
  position: absolute;
  top: 50%;
  right: -17px;
  transform: translateY(-50%);
}

.ourStoryTab {
  flex: 1 1 0;
}

.ourStoryTab ul {
  position: relative;
}

// .ourStoryTab ul li:first-child {
//   position: absolute;
// }

// .ourStoryTab ul li:nth-child(2) {
//   position: absolute;
//   left: 0px;
// }

// .ourStoryTab ul li:nth-child(2) h5 {
//   color: var(--limeMist_clr);
// }

// .ourStoryTab ul li:nth-child(3) {
//   position: absolute;
// }

// .ourStoryTab ul li:nth-child(3) h5 {
//   color: var(--limeMist_clr);
// }
// .ourStoryTab ul li:nth-child(4) {
//   position: absolute;
// }

// .ourStoryTab ul li:nth-child(4) h5 {
//   color: var(--limeMist_clr);
// }
// .ourStoryTab ul li:nth-child(5) {
//   position: absolute;
// }

// .ourStoryTab ul li:nth-child(6) {
//   position: absolute;
// }

.ourStoryTab ul li.carousel-item.active.visible h5 {
  color: var(--blankaGreen_clr);
}

.bigcircle {
  position: relative;
  // transform: translateX(-50px);
  border: 1px solid var(--verifiedBlack);
  width: 880px;
  height: 880px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 80px;
}

.bigcircle>div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
}

// .halfEllipeWave {
//   border-left: 1px solid #232327;
//   height: 300px;
// }

.halfEllipeControl {
  position: absolute;
  right: 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  // height: 100vh;
  gap: 100px;
}

.topArrowBtn {
  position: absolute;
  top: 40px;
  left: -120px;
  // top: 0px;
  // left: -10px;
  // width: 20px;
}

.BottomArrowBtn {
  position: absolute;
  bottom: 30px;
  left: -127px;
  //   bottom: 0;
  //   left: -10px;
  //   width: 20px;
}

.ourStoryTab ul li {
  // padding-bottom: 60px;
  display: block;
}

// .ourStoryTab ul li:first-child {
//   transform: translateX(-200px);
// }

// .ourStoryTab ul li:nth-child(2) {
//   transform: translateX(-100px);
// }

// .ourStoryTab ul li:nth-child(3) {
//   transform: translateX(-40px);
// }

// .ourStoryTab ul li:nth-child(4) {
//   transform: translateX(-0px);
// }

// .ourStoryTab ul li:nth-child(5) {
//   transform: translateX(0px);
// }

// .ourStoryTab ul li:nth-child(6) {
//   transform: translateX(-100px);
// }

// .ourStoryTab ul li:nth-child(7) {
//   transform: translateX(-200px);
// }
.ourStoryTab ul li h5 {
  font-size: 32px;
  line-height: 1.5;
  color: var(--white_clr);
  font-weight: 400;
  font-family: var(--AtypDisplay_fontFamily);
  margin-bottom: 12px;
}

.ourStoryTab ul li p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--cerebralGrey_clr);
  font-family: var(--Syne_fontFamily);
  margin: 0px;
}

.ourStorySecCircle>div {
  display: flex;
  align-items: center;
  gap: 60px;
}


.ourStoryAccordion {
  display: none;
}

.ourStoryAccordion .collapse {
  visibility: unset !important;
}

.ourStoryAccordion {
  padding: 0px 24px 40px;
}

.ourStoryAccordion {
  .accordion-button:focus {
    box-shadow: unset;
    border-radius: unset;
  }

  .accordion-button:after {
    content: "";
    background-image: url("./assets/images/formkit_down.svg") !important;
    background-repeat: no-repeat;
    // background-position: right;
    width: 24px;
    height: 11px;
    background-size: 24px;
  }

  .accordion-button.collapsed:after {
    content: "";
    background-image: url("./assets/images/formkit_up.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 11px;
    background-size: 24px;
  }

  .accordion-header {
    //  background: var(--drWhite_clr) !important;
    background: transparent !important;
    // border-radius: 10px;
  }

  .accordion-collapse.show {
    border-top: 1px solid #232327 !important;
  }

  .accordion-body {
    background: transparent !important;
    padding: 20px;
    // border: 1px solid #232327 !important;
    // border-top: unset !important;
    // border-radius: 7px !important;
    margin: 0px;
  }

  .accordion-item {
    margin-bottom: 16px;
    background: transparent !important;
    // border: none !important;
    border: 1px solid #232327 !important;
    border-radius: 10px;
  }

  .accordion-item:last-child {
    margin: 0px;
  }

  .accordion-header button {
    background: transparent !important;
    text-decoration: none !important;
    color: var(--blankaGreen_clr) !important;
    line-height: 1.5;
    font-weight: var(--fw500);
    font-family: var(--AtypDisplay_fontFamily) !important;
    font-size: 24px;
    width: 100%;
    padding: 20px;
    position: relative;
    text-align: left;
    word-break: break-word;
    white-space: initial;
    border-radius: 10px;
  }

  .accordion-button:not(.collapsed) {
    background-color: var(--silverpolish_clr);
    box-shadow: unset;
    border-radius: 10px !important;
  }

  .accordion-item:has(.accordion-collapse.collapse.show) .accordion-header {
    border-radius: 10px 10px 0px 0px;
  }

  .accordion-item:has(.accordion-collapse.collapse.show) .accordion-body {
    border-radius: 0px 0px 10px 10px;
  }
}

.about_whoWeAre {
  padding: 100px 0px;
}

.about_whoWeAreItem {
  position: relative;
}

.about_whoWeAreItem .about_WWA_content {
  background: linear-gradient(136deg,
      var(--verifiedBlack) 9.59%,
      #141414 98.59%);
  border: 1px solid var(--blackSuede_clr);
  padding: 34px;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 359px;
  gap: 50px;
  position: absolute;
  z-index: 1;
  transition: transform 0.6s ease-in-out,
    border 0.6s ease-in-out;
}

.about_WWA_content h3 {
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 96px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0px;
  color: var(--whitesmoke_clr);
}

.about_WWA_content p {
  font-family: var(--Syne_fontFamily);
  font-size: 32px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: var(--limeMist_clr);
  text-align: right;
}

.about_whoWeAreItem.WWA_Item_leftAnimation:hover .about_WWA_content {
  transform: translate(60%, 0%) rotate(5.45deg);
  border: 1px solid #141414;
  transition: transform 0.6s ease-in-out, border 0.6s ease-in-out;
  // animation: rightRotate 2s ease-in-out forwards;
}

.about_whoWeAreItem.WWA_Item_rightAnimation:hover .about_WWA_content {
  transform: translate(-60%, 0%) rotate(-5.45deg);
  border: 1px solid #141414;
  transition: transform 0.6s ease-in-out, border 0.6s ease-in-out;
  // animation: rightRotate 2s ease-in-out forwards;
}

.WWA_Item_rightAnimation p {
  text-align: right !important;
}

.WWA_Item_rightAnimation .about_WWA_InsiteContent div:nth-child(2) {
  display: flex;
  justify-content: end;
}

.about_WWA_InsiteContent {
  background: var(--blankaGreen_clr);
  // border: 1px solid var(--blankaGreen_clr);
  padding: 34px;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  min-height: 359px;
  gap: 20px;
  position: relative;
  justify-content: space-between;
}

.WWA_Item_leftAnimation .about_WWA_InsiteContent>div:first-child {
  width: 380px;
}

.WWA_Item_rightAnimation .about_WWA_InsiteContent>div:first-child {
  width: 380px;
  margin-left: auto;
}

.about_WWA_InsiteContent p {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--Syne_fontFamily);
  margin: 0;
  color: var(--verifiedBlack);
  text-align: left;
}

// .about_whoWeAreItem:not(:hover) .about_WWA_content {
//   animation: leftRotate 2s ease-in-out forwards;
// }
// @keyframes rightRotate {
//   from {
//     transform: translate(0%, 0%) rotate(0deg);
//   }

//   to {
//     transform: translate(60%, 0%) rotate(5.45deg);
//   }
// }
// @keyframes leftRotate {
//   from {
//     transform: translate(60%, 0%) rotate(5.45deg);
//   }

//   to {
//     transform: translate(0%, 0%) rotate(0deg);
//   }
// }


.WWA_Item_rightAnimation:not(:hover) .about_WWA_InsiteContent::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0px;
  background-image: linear-gradient(to left, var(--verifiedBlack), transparent);
  transition: .5s;
  border-radius: 20px;
  opacity: 0.8;
}

.WWA_Item_leftAnimation:not(:hover) .about_WWA_InsiteContent::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0px;
  background-image: linear-gradient(to right, var(--verifiedBlack), transparent);
  transition: .5s;
  border-radius: 20px;
  opacity: 0.8;
}




.About_whoWeareFontsizeIncrease {
  padding: 50px 0px;
  position: relative;
}

.About_whoWeareFontsizeIncrease h4 {
  font-size: 180px;
}

.About_whoWeareFontsizeIncrease h4,
.About_whoWeareFontsizeIncrease p {
  color: var(--drWhite_clr);
}

.ourCrew_bnr {
  position: relative;
  // height: 260px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  margin: 50px 0px 30px;
}

.ourCrew_bnr::before {
  content: '';
  background: url('./assets/images/ourCrewTextborder.png') no-repeat;
  width: 730px;
  height: 300px;
  right: 30px;
  position: absolute;
  top: 0px;
  background-position: right;
  background-repeat: no-repeat;
  z-index: -1;
}

// .ourCrew_Slider .slick-slide {
//   padding: 0px 16px;
//   /* Adjust the margin as needed */
// }

.ProfileImage {
  margin-bottom: 16px;
}

.ProfileImage img {
  width: 400px;
  border-radius: 30px;
}

.ourCrewItem h2 {
  font-size: 44px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--AtypDisplay_fontFamily);
  color: var(--cerebralGrey_clr);
  margin: 0px;
}

.ourCrewItem p {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--Syne_fontFamily);
  color: var(--SteelWool_clr);
  margin: 0px;
}

.ourCrew_Slider {
  padding: 50px 0px 80px;
  margin-left: 124px;
}

.ourCrew_Slider .slick-slide {
  padding: 0px 16px;
}

.ourCrewItem:hover h2 {
  color: var(--limeMist_clr);
}

.ourCrew_Slider .slick-center .ourCrewItem h2 {
  color: var(--limeMist_clr);
}

.awardsRecognitions_sec {
  padding: 100px 0px;
}

.awards_detail {
  width: 100%;
}

.awards_detail h4 {
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 36px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--white_clr);
  padding: 16px 0px;
}

.awards_detail h3 {
  padding: 16px 0px;
  font-family: var(--Syne_fontFamily);
  font-size: 44px;
  line-height: 1.5;
  color: var(--limeMist_clr);
  font-weight: 400;
}

.awards_detail .awardsDetail_item {
  position: relative;
  cursor: pointer;
}

.hoverImgMove {
  position: relative;
}

.swipeimage {
  position: absolute;
  inset-inline-start: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%;
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  margin: -200px 0 0 -150px;
  z-index: -1;
  top: 0;
  width: 250px;
  height: 250px;
  border-radius: 10px;
}

// .hoverImgMove img{
//   width: 250px;
//   height: 250;
//   border-radius: 10px;
// }

.blog-thumb-hover {
  transform: translate(640px, 249.267px);
}

// .awardsDetail_item:hover .blog-thumb-hover {
//   opacity: 1;
// }

// .blog-thumb-hover {
//   width: 330px;
//   height: 330px;
//   position: absolute;
//   top: 0;
//   inset-inline-start: 0;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position-x: 75%;
//   // opacity: 0;
//   transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
//   margin: -200px 0 0 -150px;
//   overflow: hidden;
//   pointer-events: none;
//   z-index: -1;
// }

.ourClients_sec {
  padding: 100px 0px;
}

.customerLogos {
  overflow: hidden;
  white-space: nowrap;
  // display: flex;
  // flex-direction: column;
}

.customerLogos>div {
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  scroll-snap-type: x mandatory;
  /* Adjust the margin as needed */
}

.customerLogos>div>.clientLogo_item {
  flex-shrink: 0;
  scroll-snap-align: start;
}

.customerLogos>div:first-child {
  //   -webkit-animation: slide-har 10s linear infinite;
  //   animation: slide-har 10s linear infinite;
  //   // animation: marquee_animation1 ease-in-out 15s 0s infinite;
  margin-bottom: 34px;
  margin-bottom: 10px;
}

// .customerLogos>div:nth-child(2) {
//   // animation: marquee_animation2 ease-in-out 15s 0s infinite;
//   -webkit-animation: slide-har-revers 10s linear infinite;
//   animation: slide-har-revers 10s linear infinite;
// }

.clientLogo_item {
  border: 1px solid #82828266;
  background: #c6c6c666;
  // background: var(--verifiedBlack);
  width: 280px;
  height: 280px;
  margin-right: 4px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.clientLogo_item img {
  width: 190px;
  // filter: grayscale(1);
}

@keyframes marquee_animation1 {
  0% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(-80%);
  }
}

/* Define the animation for right movement */
@keyframes marquee_animation2 {
  0% {
    transform: translateX(-80%);
  }

  100% {
    transform: translateX(-10%);
  }
}

.aboutWhoWeareEffects {
  position: relative;
}

.aboutWhoWeareEffects::after {
  content: '';
  background: var(--limeMist_clr);
  filter: blur(100px);
  width: 200px;
  position: absolute;

  z-index: -1;
  opacity: 0.6;
  height: 200px;
}

.aboutWhoWeareEffects.center::after {
  top: 100px;
  right: -20%;
}

.aboutWhoWeareEffects.left::after {
  top: 80%;
  left: 0;
}

.aboutWhoWeareEffects.right::after {
  top: 120px;
  right: 0;
}

.services_bnr {
  padding: 120px 0px;
}

.servicesContent h4 {
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 24px;
  line-height: 1.5;
  color: var(--cerebralGrey_clr);
  margin: 0;
}

.servicesContent h1 {
  color: transparent;
  // -webkit-text-fill-color: #FFF;
  -webkit-text-stroke-width: 1.15px;
  -webkit-text-stroke-color: var(--blankaGreen_clr);

  // border-image-source: ;
  font-size: 96px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0px;
  opacity: 0.7;
  font-family: var(--AtypDisplay_fontFamily);
}

.servicesContent h2 {
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 84px;
  line-height: 100px;
  color: var(--drWhite_clr);
  margin-bottom: 34px;
}

.servicesImages img {
  border-radius: 30px;
  width: 100%;
  height: 549px;
  object-fit: cover;
}

.servicesItem {
  padding-bottom: 200px;
}

.ourExpertise_sec {
  position: relative;
  overflow: hidden;
  padding: 100px 0px;
}

.services_sec {
  background: url('./assets/images/servidebg_line.png');
  background-repeat: no-repeat;
  width: 100%;
  // height: 100%;
  background-size: 100%;
  background-position: top center;
}

.ourExpertise_sec::before {
  content: '';
  background: var(--limeMist_clr);
  filter: blur(80px);
  // width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  opacity: 0.3;
  // height: 600px;
  width: 480px;
  height: 480px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.ourExpertise_sec::after {
  content: '';
  background: url('./assets/images/limeMistdot.png');
  background-position: center;
  width: 600px;
  position: absolute;
  top: 10%;
  left: 30%;
  z-index: -1;
  opacity: 0.6;
  height: 600px;
  border-radius: 50%;
  // transform: translate(-50%, -50%);
  // -webkit-animation: traey 5s infinite linear;
  // animation: traey 5s infinite linear;
  animation-fill-mode: both;
  animation-duration: 30s;
  animation-delay: 0ms;
  animation-name: limeMistdot;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes limeMistdot {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ourExpertiseItems {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  padding: 24px 0px;
}

.ourExpertCircleOne::before,
.ourExpertCircleTwo::before,
.ourExpertCircleThree::before,
.ourExpertCircleFour::before,
.ourExpertCircleFive::before,
.ourExpertCircleSix::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #92d43f1a;
  // outline-offset: 7px;
  -webkit-animation: pulse 2s ease-out infinite;
  animation: pulse 2s ease-out infinite;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 50%;
}

@keyframes pulse {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: opacity(0.9);
    filter: opacity(0.9);
  }

  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: opacity(0);
    filter: opacity(0);
  }
}

.ourExpertCircleOne {
  background: linear-gradient(90deg, #92d43f 0%, #d8ffa7 55%, #f3ffe4 100%);
  width: 121px;
  height: 121px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  -webkit-animation: traey 5s infinite linear;
  animation: traey 5s infinite linear;
  top: -16px;
  right: 60px;
}

.ourExpertCircleTwo {
  position: relative;
  // outline: 1px solid #92D43F1A;
  //   outline-offset: 7px;
  background: linear-gradient(90deg, #92d43f 0%, #d8ffa7 55%, #f3ffe4 100%);
  border-radius: 50%;
  width: 168px;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  -webkit-animation: traey 5s infinite linear;
  animation: traey 5s infinite linear;
  right: -60px;
}

.ourExpertCircleThree {
  position: relative;
  // outline: 1px solid #92D43F1A;
  // outline-offset: 7px;
  background: linear-gradient(90deg, #92d43f 0%, #d8ffa7 55%, #f3ffe4 100%);
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  -webkit-animation: traey 5s infinite linear;
  animation: traey 5s infinite linear;
  bottom: -84px;
  left: 160px;
}

.ourExpertCircleFour {
  position: relative;
  // outline: 1px solid #92D43F1A;
  // outline-offset: 7px;
  background: linear-gradient(90deg, #92d43f 0%, #d8ffa7 55%, #f3ffe4 100%);
  border-radius: 50%;
  width: 168px;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  -webkit-animation: traey 5s infinite linear;
  animation: traey 5s infinite linear;
  left: -200px;
  top: -90px;
}

.ourExpertCircleFive {
  // outline: 1px solid #92D43F1A;
  // outline-offset: 7px;
  background: linear-gradient(90deg, #92d43f 0%, #d8ffa7 55%, #f3ffe4 100%);
  border-radius: 50%;
  width: 121px;
  height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  -webkit-animation: traey 5s infinite linear;
  animation: traey 5s infinite linear;
  bottom: -40px;
}

.ourExpertCircleSix {
  // outline: 1px solid #92D43F1A;
  // outline-offset: 7px;
  background: linear-gradient(90deg, #92d43f 0%, #d8ffa7 55%, #f3ffe4 100%);
  border-radius: 50%;
  width: 155px;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  -webkit-animation: traey 5s infinite linear;
  animation: traey 5s infinite linear;
  left: 160px;
  top: -90px;
}

.ourProcessItem {
  background: var(--verifiedBlack);
  border-radius: 40px;
  border: 0.5px solid #464646;
  padding: 60px 30px 40px;
  display: flex !important;
  // align-items: center;
  flex-direction: column;
  // justify-content: space-between;
  min-height: 450px;
  max-height: 450px;
  position: relative;
  overflow: hidden;
  z-index: 999;
  // transition: 0.5s;
  transition: border 0.5s ease;
  gap: 16px;
}

.ourProcessItem:hover {
  border: 0.5px solid var(--limeMist_clr);
}

.ourProcessItem::after {
  content: '' !important;
  background-image: 50%;
  background-position: top right !important;
  background-repeat: no-repeat !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.ourProcessItem.op_first::after {
  background: url('./assets/images/squareCube.svg');
  width: 296px;
  width: 309px;
}

.ourProcessItem.op_second::after {
  background: url('./assets/images/op_second_bg.svg');
  width: 332px;
  width: 310px;
}

.ourProcessItem.op_third::after {
  background: url('./assets/images/op_third_bg.svg');
  width: 234px;
  width: 239px;
}

.ourProcessItem.op_fourth::after {
  background: url('./assets/images/op_fourth_bg.svg');
  width: 285px;
  height: 243px;
}

.ourProcessItem h5 {
  color: var(--SteelWool_clr);
  font-size: 44px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--AtypDisplay_fontFamily);
}

.ourProcessItem h4 {
  color: var(--drWhite_clr);
  font-size: 34px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--AtypDisplay_fontFamily);
  z-index: 999;
  position: relative;
}

.ourProcess_sec {
  padding: 100px 0px;
  position: relative;
}

.ourProcess_sec::before {
  content: '';
  background: url('./assets/images/ourProcessTextborder.png') no-repeat;
  width: 939px;
  height: 378px;
  left: 50%;
  position: absolute;
  top: 0px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  transform: translate(-50%, 60px);
}

.ourProcess_sec .slick-slide {
  padding: 6px 16px;
  /* Adjust the margin as needed */
}

.ourProcess_sec .slick-center .ourProcessItem h5 {
  color: var(--limeMist_clr);
}

.ourProcess_sec .ourProcessItem:hover h5 {
  color: var(--limeMist_clr);
}

.ourProcess_slider {
  padding: 80px 0px 0px 120px;
}

// .lines {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   height: 690px;
//   margin: auto;
//   width: 90vw;
// }

// .line {
//   position: absolute;
//   width: 1px;
//   height: 100%;
//   top: 0;
//   left: 50%;
//   background: rgba(255, 255, 255, 0.1);
//   overflow: hidden;
// }

// .line::after {
//   content: '';
//   display: block;
//   position: absolute;
//   height: 15vh;
//   width: 100%;
//   top: -50%;
//   left: 0;
//   background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 75%, #fff 100%);
//   animation: drop 7s 0s infinite;
//   animation-fill-mode: forwards;
//   animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
// }

// .line:nth-child(1) {
//   margin-left: -25%;
// }

// .line:nth-child(1)::after {
//   animation-delay: 2s;
// }

// .line:nth-child(3) {
//   margin-left: 25%;
// }

// .line:nth-child(3)::after {
//   animation-delay: 2.5s;
// }

// @keyframes drop {
//   0% {
//     top: -50%;
//   }

//   100% {
//     top: 110%;
//   }
// }

// @keyframes moveUpDown {
//   0% {
//     transform: translateY(-100%);
//   }

//   50% {
//     transform: translateY(0);
//   }

//   100% {
//     transform: translateY(100%);
//   }
// }

// .Main_menu {
//   animation: moveUpDown 1s infinite alternate;
// }

// .tr__overlay {
//   animation: moveUpDown 1s alternate-reverse;
// }

// Mobile Responsive
.contactus_marquee {
  padding: 40px 0px 80px;
}

.contactus_marquee .item h3 {
  font-size: 96px;
  opacity: 0.7;
  line-height: 1.5;
  font-weight: 800;
  margin: 0px;
  white-space: nowrap;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--drWhite_clr);
}

.contactus_marquee .item h3 span {
  font-weight: 100 !important;
  -webkit-text-stroke-width: medium !important;
  -webkit-text-stroke-color: var(--drWhite_clr) !important;
}

.main-marq {
  overflow-x: hidden;
}

.main-marq .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  position: relative;

  -webkit-animation: slide-har 10s linear infinite;
  animation: slide-har 10s linear infinite;
  scroll-snap-type: x mandatory;
}

.main-marq {
  position: relative;
  padding: 0;
}

.fullImageOnePage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  position: relative;
  // -webkit-animation: slide-har-revers 10s linear infinite;
  // animation: slide-har-revers 10s linear infinite;
  // scroll-snap-type: x mandatory;
}

.latestUpdate_sec {
  overflow: hidden;
}

.latestUpdateImg_grid {
  flex-shrink: 0;
  scroll-snap-align: start;
}

.latestUpdateImg_grid img {
  width: 100%;
}

@-webkit-keyframes slide-har {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes slide-har {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-har-revers {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes slide-har-revers {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.latestUpdateImg_grid::before {
  content: '';
  background: #121315;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
}

.latestUpdate_content h1 {
  font-size: 96px;
  line-height: 100px;
  font-weight: 300;
  color: var(--drWhite_clr);
}

.latestUpdate_content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  transform: translateY(-50%);
  text-align: center;
}

.latestUpdateImg_grid {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  /* overflow: hidden shouldn't break this? */
  // overflow: hidden;
  position: relative;
}

.latestUpdateImg_grid>div {
  display: grid;
  gap: 1rem;
  align-content: start;
}

.latestUpdateImg_grid>div img {
  // max-width: 100%;
  border: 0;
  padding: 0;
  // max-height: 100%;
  width: 100%;
  // aspect-ratio: 5 / 7;
  // background: hsl(0 0% 80%);
}

// .backtoTop{
//   position: fixed;
// bottom: 70px;
//   right: 30px;
// }
.topscrollBtn {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--drWhite_clr);
  background: transparent;
  border-color: transparent;
  font-family: var(--Syne_fontFamily);
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 70px;
  right: 30px;
  transition: opacity 0.3s ease-in-out;
}

.topscrollBtn:hover {
  color: var(--limeMist_clr);
}

.topscrollBtn:hover img {
  -webkit-animation: bounceTop 3s infinite;
  animation: bounceTop 3s infinite;
}

@-webkit-keyframes bounceTop {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-moz-keyframes bounceTop {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounceTop {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.circleAnimationLinerOne {
  width: 297px;
  height: 272px;
  position: absolute;
  background: url('./assets/images/doubleCircle_ani.svg');
  background-repeat: no-repeat;
  left: 60%;
  top: 30px;
  z-index: -1;
  display: block;
  animation-fill-mode: both;
  animation-duration: 5000ms;
  animation-delay: 0ms;
  animation-name: moving;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.circleAnimationLinerTwo {
  width: 297px;
  height: 272px;
  position: absolute;
  background: url('./assets/images/doubleCircle_ani.svg');
  background-repeat: no-repeat;
  left: -50px;
  top: 30px;
  z-index: -1;
  background-position: center;

  display: block;
  animation-fill-mode: both;
  animation-duration: 5000ms;
  animation-delay: 0ms;
  animation-name: moving;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.circleAnimationLinerThree {
  width: 297px;
  height: 272px;
  position: absolute;
  background: url('./assets/images/doubleCircle_ani.svg');
  background-repeat: no-repeat;
  left: 30%;
  top: 70%;
  z-index: -1;
  display: block;
  animation-fill-mode: both;
  animation-duration: 5000ms;
  animation-delay: 0ms;
  animation-name: moving;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.circleAnimationLinerfour {
  width: 297px;
  height: 272px;
  position: absolute;
  background: url('./assets/images/doubleCircle_ani.svg');
  background-repeat: no-repeat;
  right: 0px;
  bottom: -180px;
  display: block;
  animation-fill-mode: both;
  animation-duration: 5000ms;
  animation-delay: 0ms;
  animation-name: moving;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.WantToWork_Sec {
  overflow: hidden;
  height: 800px;
  padding: 150px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.WantToWork_Sec h1 {
  color: var(--drWhite_clr);
  line-height: 100px;
  margin: 0 0px 60px;
  font-family: var(--AtypDisplay_fontFamily);
  font-size: 96px;
  font-weight: 300;
  text-align: center;
}

.WantToWork_Sec::before {
  content: '';
  background: var(--limeMist_clr);
  filter: blur(100px);
  width: 300px;
  position: absolute;
  top: 55%;
  left: 50%;
  z-index: 1;
  opacity: 0.6;
  height: 300px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.WantToWork_Sec::after {
  content: '';
  background: url('./assets/images/limeMistdot.png');
  background-position: center;
  background-size: contain;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 40%;
  z-index: 1;
  height: 300px;
  border-radius: 50%;
  // transform: translate(-50%, -50%);
  // -webkit-animation: traey 5s infinite linear;
  // animation: traey 5s infinite linear;
  animation-fill-mode: both;
  animation-duration: 30s;
  animation-delay: 0ms;
  animation-name: limeMistdot;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes traey {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  10% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes moving {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.InstaLink {
  font-size: 32px;
  font-weight: 500;
  color: var(--drWhite_clr);
  font-family: var(--Syne_fontFamily);
  display: flex;
  align-items: center;
  gap: 30px;
}

.InstaLink img {
  transition: transform 0.7s ease-in-out;
}

.InstaLink:hover img {
  transform: rotate(-45deg);
}

.InstaLink span {
  transition: 0.3s ease-in-out;
}

.InstaLink:hover span {
  background: linear-gradient(90deg,
      #8c26f3 0%,
      #b604ee 27.86%,
      #eb009c 50.06%,
      #ff4818 76.01%,
      #feb100 96.48%);
  // background: -webkit-linear-gradient(#eee, #333);
  // background: -webkit-linear-gradient(90deg, #8C26F3 0%, #B604EE 27.86%, #EB009C 50.06%, #FF4818 76.01%, #FEB100 96.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whatsappLink img {
  transition: transform 0.7s ease-out;
  // transition: transform .2s ease-out;
}

.whatsappLink {
  font-size: 32px;
  font-weight: 500;
  color: var(--drWhite_clr);
  font-family: var(--Syne_fontFamily);
  display: flex;
  align-items: center;
  gap: 30px;
}

.whatsappLink:hover img {
  transform: rotate(45deg);
}

.whatsappLink:hover span {
  background: linear-gradient(90deg,
      #58f675 0%,
      #2fd74d 46.95%,
      #ffffff 91.95%);
  // background: -webkit-linear-gradient(90deg, #8C26F3 0%, #B604EE 27.86%, #EB009C 50.06%, #FF4818 76.01%, #FEB100 96.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LinkedInLink {
  font-size: 32px;
  font-weight: 500;
  color: var(--drWhite_clr);
  font-family: var(--Syne_fontFamily);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.LinkedInLink img {
  transition: transform 0.7s ease-in-out;
}

.LinkedInLink:hover img {
  transform: rotate(30deg);
}

.LinkedInLink:hover span {
  background: linear-gradient(270deg, #ffffff 0%, #0a66c2 100%);
  // background: -webkit-linear-gradient(#eee, #333);
  // background: -webkit-linear-gradient(90deg, #8C26F3 0%, #B604EE 27.86%, #EB009C 50.06%, #FF4818 76.01%, #FEB100 96.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fbLink {
  font-size: 32px;
  font-weight: 500;
  color: var(--drWhite_clr);
  font-family: var(--Syne_fontFamily);
  display: flex;
  align-items: center;
  gap: 30px;
}

.fbLink img {
  transition: transform 0.7s ease-in-out;
}

.fbLink:hover img {
  transform: rotate(30deg);
}

.fbLink:hover span {
  background: linear-gradient(90deg, #ffffff 0%, #1877f2 100%);
  // background: -webkit-linear-gradient(#eee, #333);
  // background: -webkit-linear-gradient(90deg, #8C26F3 0%, #B604EE 27.86%, #EB009C 50.06%, #FF4818 76.01%, #FEB100 96.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.videoSection {
  position: relative;
}

.videoSection video {
  border-radius: 40px;
}

.VideoIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.projectDetailSec {
  padding: 80px 0px 0px;
}

.projectDetailHeader {
  display: flex;
  align-items: center;
  // gap: 104px;
  gap: 50px;
}

.projectDetail_icon img {
  width: 88px;
}

.projectDetailHeader h1 {
  font-family: var(--AtypDisplay_fontFamily);
  // font-size: 140px;
  font-size: 96px;
  line-height: 1.5;
  font-weight: 600;
  color: var(--drWhite_clr);
}

.videoSection {
  padding: 100px 0px;
}

.footerLogo {
  margin-bottom: 24px;
}

.footerLogo img {
  width: 420px;
}

.socialMediaLinks ul {
  display: flex;
  align-items: center;
  gap: 24px;
}

.socialMediaLinks ul li a {
  overflow: hidden;
}

.socialMediaLinks ul li a img:hover {
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
}

.socialMediaLinks ul li a img {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  width: 40px;
}

.quickLink ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.quickLink ul li {
  margin-bottom: 16px;
}

.quickLink ul li button {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  font-family: var(--AtypDisplay_fontFamily);
  color: var(--white_clr);
  display: flex;
  align-items: center;
  gap: 16px;
  transition: 0.5s;
}

.quickLink ul li button img {
  display: none;
}

// .quickLink ul li button:hover img {
//   display: block;
//   -webkit-animation: bounceRight 2s infinite;
//   animation: bounceRight 2s infinite;
//   float: right;
// }
.quickLink ul li button:hover {
  color: var(--blankaGreen_clr);
  transform: translateX(12px);
  transition: all 0.5s ease;
}

.hrefHover:hover {
  color: var(--blankaGreen_clr) !important;
}

.shareViaLink ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shareViaLink ul li img {
  width: 50px;
}

.testimonialArrow {
  position: absolute;
  top: -27%;
  right: 50px;
}

.wwd_carousel {
  width: 352px;
  height: 545px;
}

.wwd_carousel .carousel-indicators {
  flex-direction: column;
  right: -30px;
  left: unset;
  bottom: unset;
  top: 0px;
  margin: 0px;
}

.wwd_carousel .carousel-indicators button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0px 0px 7px !important;
  border: unset !important;
}

.wwd_carousel .carousel-indicators button.active {
  background-color: var(--blankaGreen_clr);
}

.ourStorySecCircle {
  padding-bottom: 170px;
}



// Tablet view

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 6px;
  border-radius: 10px;
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: var(--dreamyCloudColor);
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--blankaGreen_clr);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--blankaGreen_clr);
}

.no-scroll {
  height: 100vh !important;
  overflow: hidden !important;
}

body.modal-open {
  height: 100vh !important;
}

.Home_bnr {
  position: relative;
}

.activeTab {
  background: var(--greenGoneWild_clr) !important;
  border-radius: 10px;
  color: white !important;
  font-weight: 600 !important;
}

// .rightHeightBnr{
//   height: calc(100% - 120px);
//   flex-direction: column;
//   display: flex;
//   justify-content: end;
// }
.bnrMain-content {
  padding-bottom: 50px;
}

.textDirection {
  padding-top: 70px;
}

@media (min-width: 1440px) {
  .container {
    max-width: 1380px !important;
  }
}






// .image-slider {
//   width: 100%;
//   height: 300px;
//   /* Adjust height as needed */
//   background-size: cover;
//   background-position: center;
//   // transition: transform 0.5s ease-in-out;
//   transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
// }



.bnr_Overlay {
  position: relative;
  webkit-animation: zoomSlow 30s infinite linear;
  -moz-animation: zoomSlow 30s infinite linear;
  animation: zoomSlow 30s infinite linear;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 750px;
  transition: background-image 5s ease-in-out, transform 5s ease-in-out;
}

.bnr_Overlay::after {
  content: '';
  background: #000000cc;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}


.carousel_caption {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0px;
  text-align: unset !important;
  top: 120px;
  height: calc(100% - 120px);
}

.Home_bnr {
  overflow: hidden;
}

.Home_bnr .container {
  height: 100%;
}

.Home_bnr .row {
  height: 100%;
}

.Home_bnr .slick-dots {
  position: absolute;
  bottom: 40px;
  transform: translateX(-50%);
}

.Home_bnr .slick-dots li {
  width: unset;
  height: unset;
  margin-right: 62px;
}

.Home_bnr .slick-dots li button {
  width: unset;
  height: unset;
  padding: 0px;
}

.Home_bnr .slick-dots li button:before {
  background: #FAFAFA;
  // border: 1px solid #FAFAFA;
  // background: transparent;
  width: 60px;
  height: 2px;
  content: "" !important;
}

.Home_bnr .slick-dots li button:hover:before,
.Home_bnr .slick-dots li button:focus:before {
  background: #92D43F;
}

.Home_bnr .slick-dots li.slick-active button:before {
  // border: 1px solid #92D43F;
  background: #92D43F;
}

.projects_home {
  padding-bottom: 100px;
}

.pleftZero {
  padding-left: 0px !important;
}





.customModal {
  /* display: none; */
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /* background-color: #252423; */

}

/* Modal Content */
.customModal_content {
  /* margin: 24px auto; */
  // padding: 16px 24px 0px;
  /* border: 1px solid transparent; */
  width: 100%;
  // max-width: 960px;
  max-width: 950px !important;
  /* height: 530px; */
  height: 500px;
  /* height: calc(100% - 40px); */
  /* max-height: calc(100% - 40px); */
  overflow-y: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--verifiedBlack) !important;
  border: 1px solid #464646 !important;
  border-radius: 30px !important;
  padding: 34px;
}

.marquee {
  display: flex;
  gap: 3px;
}

// .clientLogo_item {
//   display: inline-block;
//   margin: 0 10px;
// }
.marquee-left {
  animation: scroll-left 35s linear infinite;
}

.marquee-right {
  animation: scroll-right 35s linear infinite;
}

@keyframes scroll-left {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-175%);
  }
}

@keyframes scroll-right {
  from {
    transform: translateX(-175%);
  }

  to {
    transform: translateX(0%);
  }
}

// Tablet view
.sliderWrapper {
  height: 750px;
  width: 100%;
}



.sliderWrapper {
  position: relative;
  overflow: hidden;
}

.sliderImage {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  animation: 45s ease-in-out infinite;
}

.sliderImage img {
  position: relative;
  width: 100%;
}

// .Home_bnr::before {
//   content: '';
//   background: #000000cc;
//   position: absolute;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   height: 100%;
// }


/* Name of animations and selection of images */
.sliderImage:nth-of-type(1) {
  animation-name: slide-01;
}

.sliderImage:nth-of-type(2) {
  animation-name: slide-02;
}

.sliderImage:nth-of-type(3) {
  animation-name: slide-03;
}

@-webkit-keyframes slide-01 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.4) translate(0, 0);
  }

  16% {
    opacity: 1;
    -webkit-transform: scale(1.2) translate(-20px, -10px);
  }

  33% {
    opacity: 0;
    -webkit-transform: scale(1.4) translate(0, 0);
  }

  50% {
    opacity: 0;
  }

  67% {
    opacity: 0;
  }

  84% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1.4) translate(0, 0);
  }
}

@-webkit-keyframes slide-02 {
  0% {
    opacity: 0;
  }

  16% {
    opacity: 0;
    -webkit-transform: scale(1.2) translate(-20px, -10px);
  }

  33% {
    opacity: 1;
    -webkit-transform: scale(1.4) translate(0, 0);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.2) translate(-20px, 10px);
  }

  67% {
    opacity: 0;
    -webkit-transform: scale(1.4) translate(0, 0);
  }

  84% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes slide-03 {
  0% {
    opacity: 0;
  }

  16% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(1.4) translate(0, 0);
  }

  67% {
    opacity: 1;
    -webkit-transform: scale(1.4) translate(-20px, 10px);
  }

  84% {
    opacity: 1;
    -webkit-transform: scale(1.2) translate(20px, -10px);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.4) translate(0, 0);
  }
}

/* Styles for all other browsers */
@keyframes slide-01 {
  0% {
    opacity: 1;
    transform: scale(1.4) translate(0, 0);
  }

  16% {
    opacity: 1;
    transform: scale(1.2) translate(-20px, -10px);
  }

  33% {
    opacity: 0;
    transform: scale(1.4) translate(0, 0);
  }

  50% {
    opacity: 0;
  }

  67% {
    opacity: 0;
  }

  84% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: scale(1.4) translate(0, 0);
  }
}

@keyframes slide-02 {
  0% {
    opacity: 0;
  }

  16% {
    opacity: 0;
    transform: scale(1.2) translate(-20px, -10px);
  }

  33% {
    opacity: 1;
    transform: scale(1.4) translate(0, 0);
  }

  50% {
    opacity: 1;
    transform: scale(1.2) translate(-20px, 10px);
  }

  67% {
    opacity: 0;
    transform: scale(1.4) translate(0, 0);
  }

  84% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-03 {
  0% {
    opacity: 0;
  }

  16% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: scale(1.4) translate(0, 0);
  }

  67% {
    opacity: 1;
    transform: scale(1.4) translate(-20px, 10px);
  }

  84% {
    opacity: 1;
    transform: scale(1.2) translate(20px, -10px);
  }

  100% {
    opacity: 0;
    transform: scale(1.4) translate(0, 0);
  }
}

// New Input End

@media (max-width: 767px) {
.projectTitlePart {
        display: flex;
        align-items: baseline;
        gap: 16px;
    }
        .projectTitlePart span{
            display: block;
        }
.project_icon img {
        width: 30px;
        height: 30px;
}
    .testimonials_sec .slick-slide {
        opacity: unset;
      }

            .testimonial_Item .commaIcon img {
              filter: var(--limeMistFilter);
            }
  // body {
  //   height: auto !important;
  // }

  .limeMistBorderbtn {
    height: 40px;
    font-size: 14px;
    padding: 0px 24px;
  }

  .flexDirectionColumnReverse {
    flex-direction: column-reverse;
  }

  .careerPopup .modal-content {
    max-height: 10000vh !important;
  }

  .GroupBorderinput .GroupBorderinput_text {
    white-space: unset;
  }

  .fontSize20 {
    font-size: 18px;
  }

  .skeleton_projectDetailClientName {
    top: 10px !important;
  }

  .sliderWrapper {
    height: 550px;
  }

  .wwd_carousel .carousel-indicators {
    display: none;
  }

  .sliderImage img {
    position: relative;
    height: 500px;
  }

  .Home_bnr .slick-dots {
    display: none !important;
  }

  .pr40 {
    padding-right: 0px;
  }

  .projects_home {
    padding-bottom: 0px;
  }

  .ourProcess_sec::before {
    display: none;
  }


  .pb_80 {
    padding-bottom: 40px;
  }

  .ourClients_sec::before {
    display: none;
  }

  .bnr_Overlay {
    height: 500px;
  }

  br.MobBr_hide {
    display: none;
  }

  .hamburger_menu {
    width: 60px;
  }

  .fontSize36 {
    font-size: 26px;
  }

  .sectionTitle h2 {
    font-size: 40px;
    line-height: 1.5;
    text-align: center;
    z-index: unset;
  }

  .sectionTitle h2 br {
    display: none;
  }

  .MobSpaceBetween {
    justify-content: space-between;
  }

  .Mobgap24 {
    gap: 24px;
  }

  .MobFlexWrapgap16 {
    flex-wrap: wrap;
    gap: 16px;
  }

  .mobileNone {
    display: none;
  }

  .desktopFlex_mobileBlock {
    display: block;
  }

  .pleftZero {
    padding: 0px 12px !important;
  }

  .fontSize16 {
    font-size: 14px;
  }

  .fontSize18 {
    font-size: 16px;
  }

  .fontSize32 {
    font-size: 22px !important;
  }

  .fontSize34 {
    font-size: 24px;
  }

  .fontSize48 {
    // font-size: 38px !important;
    font-size: 34px !important;
  }

  .fontSize96 {
    font-size: 40px !important;
  }

  .fontSize30 {
    font-size: 20px;
  }

  .fontSize24 {
    font-size: 18px !important;
  }

  .split-child {
    padding-bottom: 0px !important;
  }

  .quickLink ul li button {
    font-size: 18px;
  }

  .footerProjectFrame ul li button {
    font-size: 18px;
  }

  .mobSpace_mb16 {
    margin-bottom: 16px;
  }

  .mobSpace_mb24 {
    margin-bottom: 24px;
  }

  .mobSpace_mb34 {
    margin-bottom: 34px;
  }

  .pb_100 {
    padding-bottom: 40px;
  }

  .pb_150 {
    padding-bottom: 40px;
  }

  .mb_34 {
    margin-bottom: 24px !important;
  }

  .mailtobtn {
    font-size: 24px;
  }

  .mailtobtn img {
    display: none;
  }

  .careerItem {
    height: 400px;
  }

  .Home_bnr .bnrMain-content h1 {
    font-size: 50px;
    line-height: 50px;
  }

  .Home_bnr .bnrMain-content h2 {
    font-size: 50px;
    line-height: 50px;
  }

  .Home_bnr .bnrMain-content p {
    font-size: 18px;
    font-weight: 400;
  }

  .Home_bnr .carousel-item img.bnrImage {
    min-height: 670px;
  }

  .Home_bnr .carousel-item {
    height: auto;
  }

  .Home_bnr .carouselCaption {
    right: 24px;
    left: 24px;
    top: 90px;
  }

  .brandLogo {
    position: relative;
    display: inline-block;
    transition: 0.8s all ease;
    overflow: hidden;
  }

  .brandLogo img {
    width: 230px;
    // width: 420px;
  }
.brandLogo_preloader{
  text-align: center;
  
}
  .brandLogo_preloader img {
    width: 160px;
  margin: 0px auto;
    display: block;
  }

  header {
    padding: 16px 0px;
  }

  .whoWeare_sec {
    margin: 40px 0px;
  }

  .whatWedo_sec {
    padding: 0px 0px 40px;
  }

  .whoWeare_sec::before {
    content: unset;
  }

  .whoWeAreItem p {
    font-size: 22px;
  }

  .whatWedo_sec::before {
    content: unset;
  }

  .testimonials_sec::before {
    content: unset;
  }

  .VerticalSteps {
    display: block;
  }

  .latestUpdate_sec {
    padding: 40px 0px;
  }

  .tr__overlay {
    padding: 100px 16px 0px;
  }

  .Main_menu ul li button {
    font-size: 24px;
    line-height: 24px;
  }

  .borderRadius30 {
    border-radius: 10px;
  }

  .projectsTab ul li button.active {
    padding: 0px 44px;
  }

  .Main_menu ul li {
    margin-bottom: 16px;
  }

  .bg_paragraph p {
    font-size: 26px;
    line-height: 1.5;
    padding: 0px !important;
  }

  .bg_paragraph::after {
    content: '';
    background-color: var(--black_clr);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .bg_paragraph::before {
    width: 100%;
    height: 100%;
  }

  .homeProject_Wrapper {
    padding: 40px 0px;
  }

  .homeProject_InnerContent {
    padding: 24px 16px 0px;
  }

  .homeProject_Item img {
    width: 100%;
    height: auto;
    padding: 0px;
  }

  .lightGreenEffectBtn {
    font-size: 18px;
    height: 44px;
    padding: 0px 24px;
  }

  .width250px {
    width: auto;
  }

  .homeProject_InnerContent h2 {
    font-size: 60px;
  }

  .homeProject_InnerContent h5 {
    font-size: 22px;
  }

  .homeProject_InnerContent p {
    font-size: 14px;
  }

  .VerticalStepsWrapper {
    padding: 40px 0px 0px;
  }

  .VerticalStepsWrapper>div:first-child>div:first-child>div {
    padding: 0px 16px 16px;
  }

  .VerticalStepsWrapper>div:first-child>div:nth-child(3)>div{
    padding: 40px 16px;
  }

  .VerticalStepsWrapper>div:nth-child(2)>div:nth-child(3)>div{
    padding: 40px 16px;
  }

  // .VerticalStepsWrapper>div:first-child>div:nth-child(3) div {
  //   padding: 40px 24px;
  // }

  .VerticalStepsWrapper>div:nth-child(2)>div:first-child>div {
    padding: 40px 16px;
  }

  .VerticalStep img {
    width: 100%;
  }


  .testimonials_sec {
    margin: 0px 0px 40px;
  }

  .testimonials_sec .slick-slide:nth-child(even) {
    margin: 0px !important;
  }

  footer {
    padding: 40px 0px 100px;
  }

  .testimonial_Item p {
    font-size: 16px;
  }

  .testimonial_Item .commaIcon img {
    width: 40px;
  }

  .whoWeAreItem {
    width: 100%;
    height: 230px;
    margin-bottom: 24px;
  }

  .bg_paragraph {
    padding: 34px 0px;
    margin: 0px 0px 40px;
  }

  .projects_home::before {
    content: unset !important;
  }

  .aboutUs_intro {
    padding: 0px;
  }

  .fontSize40 {
    font-size: 24px;
  }

  .commonPage_bnr h1 {
    line-height: 1.5;
  }

  .commonPage_bnr::before {
    content: unset;
  }

  .weHandledSec::before {
    content: unset;
  }

  .weHandledSec {
    padding-bottom: 40px;
  }

  .commonPage_bnr {
    height: auto;
    padding: 60px 0px 40px;
  }

  .services_bnr {
    padding: 40px 0px;
  }

  .contactUs_bnr {
    padding: 40px 0px;
  }

  .ourStory_bnr::before {
    content: unset;
  }

  .about_WWA_content h3 {
    font-size: 40px;
  }

  .about_WWA_content {
    gap: 40px;
  }

  .about_whoWeAre {
    padding: 0px 0px 40px;
  }

  .about_WWA_content p {
    font-size: 22px;
  }

  .ourCrew_bnr::before {
    content: unset;
  }

  .ourCrew_bnr {
    height: auto;
    margin: 40px 0px 0px;
  }

  .ourCrew_Slider {
    padding: 40px 0px;
    margin-left: 0px;
  }

  table.awards_detail tr th {
    font-size: 22px;
  }

  table.awards_detail tr td {
    font-size: 42px;
  }

  .ourClients_sec {
    padding: 40px 0px;
  }

  .servicesContent h2 {
    font-size: 34px;
    line-height: 1.5;
  }

  .servicesItem {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .servicesContent h1 {
    font-size: 54px;
  }

  .ourProcess_slider {
    padding: 0px;
  }

  .projectsTab ul li:first-child button {
    width: unset !important;
  }

  .projectsTab ul li button {
    font-size: 16px;
    padding: 0px 24px;
    height: 40px;
  }

  .projectsTab ul {
    gap: 24px 12px;
    padding-bottom: 60px;
  }

  .projectsFullviewleft {
    padding-bottom: 50px;
  }

  .projectsMediumviewRight {
    padding-bottom: 50px;
  }

  .projectsFullviewRight {
    padding-bottom: 50px;
  }

  .projectsSmallview_spacebetween {
    padding-bottom: 40px;
  }

  .wwd_FirstImgSize img {
    width: 100%;
    height: 457px;
  }

  .wwd_SecondImgSize img {
    width: 100%;
    height: 457px;
  }

  .wwd_ThirdImgSize img {
    width: 100%;
    height: 457px;
  }

  .wwd_FourthImgSize img {
    width: 100%;
    height: 457px;
  }


  .contactus_marquee {
    padding: 40px 0px;
  }

  .blogMain_img img {
    height: 300px;
  }

  .blogItemImage img {
    height: 300px;
  }

  // .main-marq .box{
  //   overflow-x: hidden;
  // }
  .contactus_marquee .item h3 {
    font-size: 50px;
  }

  .topscrollBtn {
    font-size: 14px;
    bottom: 30px;
    right: 20px;
  }

  .latestBlog {
    padding-bottom: 0px;
  }

  .GroupSearchinput {
    padding-bottom: 40px;
  }

  .blogDetailPage {
    padding: 40px 0px;
  }

  .ourExpertise_sec::before {
    // width: 100%;
    // content: unset;
  }

  .ourExpertise_sec::after {
    width: 100%;
    height: 100%;
    left: 0;
    z-index: unset;
  }
    .ourExpertise_sec::before{
      width: 300px;
        height: 300px;
        z-index: unset;
        opacity: 0.3;
        filter: blur(70px);
    }

  .ourProcess_sec {
    padding: 40px 0px;
  }

  .ourExpertise_sec {
    padding: 40px 0px;
  }

  .ourExpertCircleOne {
    width: 70px;
    height: 70px;
    // left: -50px;
    right: 0px;
    top: 20px;
  }

  .ourExpertCircleTwo {
    width: 70px;
    height: 70px;
    // left: -30px;
    right: 0;
    top: -30px;
  }

  .ourExpertCircleThree {
    width: 70px;
    height: 70px;
    left: 0px;
    bottom: 10px;
  }

  .ourExpertCircleFour {
    width: 70px;
    height: 70px;
    left: 0px;
    top: 0px;
  }

  .ourExpertCircleFive {
    width: 70px;
    height: 70px;
  }

  .ourExpertCircleOne img,
  .ourExpertCircleTwo img,
  .ourExpertCircleThree img,
  .ourExpertCircleFour img,
  .ourExpertCircleFive img,
  .ourExpertCircleSix img {
    width: 24px;
  }

  .ourExpertCircleOne p,
  .ourExpertCircleTwo p,
  .ourExpertCircleThree p,
  .ourExpertCircleFour p,
  .ourExpertCircleFive p,
  .ourExpertCircleSix p {
    font-size: 12px;
    font-weight: 500;
  }

  .ourExpertCircleSix {
    width: 80px;
    height: 80px;
    top: 0px;
    // left: 20px;
    left: 0px;
  }

  .ourExpertiseItems {
    justify-content: space-between;
  }

  .circleAnimationLinerfour {
    display: none;
  }

  .circleAnimationLinerTwo {

    // right: 0px;
    //   top: unset;
    //   bottom: 30px;
    //   left: 0px;
    //   background-position: right;
    display: none;
  }

  .WantToWork_Sec {
    height: 450px;
    padding: 0px;
  }

  .WantToWork_Sec::after {
    top: 20%;
    left: 10%;
  }

  .WantToWork_Sec::before {
    filter: blur(60px);
    width: 140px;
    top: 50%;
    left: 50%;
    opacity: 0.8;
    height: 140px;
  }

  .WantToWork_Sec h1 {
    font-size: 40px;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  // .circleAnimationLinerOne {
  //   // display: none;
  //   // left: 0px;
  //   // top:120px;
  //   // right: 0;
  //   // transform: translate(-50%, -50%);
  //   // background-position: center;
  //   // // width: 100%;
  //   // margin: 0px auto;
  // }

  .circleAnimationLinerThree {
    display: none;
  }

  .latestUpdate_content h1 {
    font-size: 40px;
    line-height: 1.5;
  }

  .testimonials_sec .slick-slide {
    padding: 0px 8px;
  }

  .whoWeareFontsizeIncrease {
    margin: 34px 0px;
    z-index: unset;
  }


  .whoWeareFontsizeIncrease h4 {
    font-size: 100px;
    line-height: 100px;
    color: var(--white_clr) !important;
  }

  .whoWeareFontsizeIncrease p {
    font-size: 34px;
    color: var(--white_clr) !important;
  }

  .whoWeAreItem h3 {
    font-size: 54px;
  }

  .InstaLink {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .whatsappLink {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .TwitterLink {
    font-size: 24px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .fbLink {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .LinkedInLink {
    width: 100%;
  }

  .whoWeAreItem p br {
    display: none;
  }

  .aboutUs_bnr {
    padding: 40px 0px;
  }

  .thought_bnr {
    padding: 40px 0px;
  }

  .projects_bnr {
    padding: 40px 0px;
  }

  .careers_bnr {
    padding: 40px 0px;
  }

  .projectDetailSec {
    padding: 40px 0px 0px;
  }

  .videoSection {
    padding: 40px 0px;
  }

  .ourStorySecCircle {
    padding-bottom: 0px;
  }

  .projectDetailHeader {
    margin-bottom: 16px;
    gap: 16px;
  }

  .projectDetail_icon img {
    width: 34px;
  }

  .projectDetailHeader h1 {
    font-size: 40px;
  }
    .fontSize26 {
      font-size: 16px;
    }

  .projectsList {
    padding: 0px 0px 40px;
  }

  .projectsList .slick-track {
    padding: 40px 0px 20px;
  }

  .projectsList .slick-prev,
  .projectsList .slick-next {
    top: unset;
    bottom: -16px;
  }

  .projectsList .slick-prev:before {
    left: 40px;
  }

  .projectsList .slick-next:before {
    right: 40px;
  }

  .projectsListItem img,
  .projectsListItem_skeletonImage {
    min-height: 250px;
    height: 250px;
    max-height: 250px;
    border-radius: 20px;
    object-fit: cover;
  }

  .projectsList .slick-arrow.slick-prev {
    left: 0px;
    z-index: 999;

  }

  .projectsList .slick-arrow.slick-next {
    right: 0px;
    z-index: 999;

  }

  .OtherItemImage img,
  .otherImage_skeleton {
    height: 250px;
  }


  //  .projectsList .slick-prev:before,
  //  .projectsList .slick-next:before{
  //   color: var(--blankaGreen_clr);
  //   font-size: 20px;
  //  }
  .projectsList .slick-slide {
    opacity: unset;
      padding: 0px 8px;
  }

 
  .teamcredits_sec {
    padding: 0px;
    border-bottom: unset;
  }

  .teamcredits_sec h3 {
    font-size: 24px;
  }

  .contactUs_sec {
    padding: 40px 0px;
  }

  .custom_switch {
    padding-bottom: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  .toggle_switch {
    width: 120px;
    height: 50px;
  }

  .custom_switch span {
    font-size: 20px;
  }

  .toggle_switch::before {
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
    left: 7px;
  }

  .toggle_checkbox:checked+.toggle_switch::before {
    right: 34px;
  }

  .rolesTab ul li button {
    height: 40px;
    padding: 0px 15px;
    font-size: 14px;

    border-radius: 10px;
  }

  .welcomrToOurTeam_sec {
    padding: 40px 0px;
  }

  .ourProcessItem {
    padding: 24px;
    min-height: 380px;
    max-height: 380px;
  }

  .ourProcessItem h4 {
    font-size: 24px;
  }

  .ourProcessItem h5 {
    font-size: 34px;
  }

  .shareViaLink ul {
    justify-content: start;
    flex-wrap: wrap;
    gap: 16px;
  }

  .shareViaLink ul li img {
    width: 34px;
  }

  .shareViaPopup .modal-header {
    margin-bottom: 24px;
  }

  .shareViaPopup .modal-content {
    padding: 24px;
  }

  .careerPopup .modal-content {
    padding: 24px;
  }

  .careerPopup .modal-header h2 {
    font-size: 22px;
  }
    .rolesTab ul{
      gap: 12px;
    }

  .ourStory_bnr {
    margin: 40px 0px;
    height: auto;
  }

  .ourStorySecCircle>div {
    display: flex;
    flex-direction: column-reverse;
  }

  .bigcircle {
    width: 100%;
    height: auto;
    padding-left: 0px;
    border-radius: 10px;
    margin-bottom: 40px;
  }

  .topArrowBtn {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .halfEllipeControl {
    position: unset;
  }

  .ourStoryTab ul li h5 {
    font-size: 24px;
  }

  .halfEllipeWave {
    display: none;
  }

  .ourCrewItem h2 {
    font-size: 24px;
  }

  .ourCrewItem p {
    font-size: 16px;
  }

  .About_whoWeareFontsizeIncrease h4 {
    font-size: 80px;
    line-height: 1.5;
  }

  .About_whoWeareFontsizeIncrease {
    padding: 0px;
  }

  .limeMistFullClrbtn {
    height: 40px;
    padding: 0px 24px;
    font-size: 16px;
  }

  .awards_detail h4 {
    font-size: 24px;
  }

  .awardsRecognitions_sec {
    padding: 40px 0px;
  }

  .clientLogo_item {
    width: 200px;
    height: 200px;
  }

  .clientLogo_item img {
    width: 140px;
  }

  .testimonial_Item .commaIcon {
    padding-bottom: 0px;
  }

  .ourStorySecCircle {
    display: none;
  }





  .testimonials_sec .testimonial_Item {
    min-height: 400px;
    max-height: 400px;
    padding: 24px;
  }

  .testimonial_profile img {
    width: 50px;
  }

  .servicesImages img {
    height: 300px;
  }

  .wwd_carousel {
    width: 100%;
    height: 457px;
  }

  .testimonialArrow {
    display: none;
  }

  .socialMediaLinks ul li a img {
    width: 34px;
  }

  .WWA_Item_leftAnimation .about_WWA_InsiteContent>div:first-child {
    width: 100%;
  }

  .WWA_Item_rightAnimation .about_WWA_InsiteContent>div:first-child {
    width: 100%;
  }

  .about_whoWeAreItem.WWA_Item_leftAnimation:hover .about_WWA_content {
    transform: translate(-110%, 0%) rotate(0);
  }

  .about_whoWeAreItem.WWA_Item_rightAnimation:hover .about_WWA_content {
    transform: translate(110%, 0%) rotate(0);
  }

  .about_WWA_InsiteContent {
    min-height: 280px;
    padding: 24px;
  }

  .about_whoWeAreItem .about_WWA_content {
    min-height: 280px;
    padding: 24px;
  }

  .about_WWA_InsiteContent p {
    font-size: 18px;
  }

  .cursedBlackBtn {
    font-size: 16px;
    height: 44px;
    padding: 0px 24px;
  }

  .careerPopup .modal-content {
    height: auto;
    overflow-y: unset;
  }

  .custom-file-upload {
    text-wrap: wrap;
    text-align: left;
  }

  .ourStoryAccordion {
    display: block;
  }
.GroupSearchinput input{
  height: 50px;
  font-size: 18px;
}
.GroupSearchinput .GroupSearchinput_text{
  height: 50px;
  padding: 0px 16px 0px 0px;
}
.secondaryBtn{
    font-size: 14px;
    height: 40px;
    padding: 0px 24px;
}
.mt_60mobile{
  margin-top: 60px;
}
}

@media (min-width: 768px) and (max-width: 991px) {

  .projectsList .slick-slide {
      opacity: unset;
      padding: 0px 8px;
    }
        .projectsList .slick-prev{
          left: 0;
          z-index: 1;
        }
                .projectsList .slick-next{
                  right: 0;
                }
  .servicesContent h1{
    font-size: 50px;
  }
.servicesContent h2{
  font-size: 44px;
  line-height: 1.5;
}
  .circleAnimationLinerThree{
    left: 0;
  }
  .projectDetailHeader h1 {
      font-size: 50px;
    }
  
    .fontSize26 {
      font-size: 18px;
    }
  
    .fontSize36 {
      font-size: 28px;
    }

    .careerPopup .modal-header h2 {
        font-size: 24px;
      }
        .careerPopup .modal-header{
          margin-bottom: 24px;
        }
      .rolesTab ul li button {
        height: 32px;
        padding: 0px 16px;
        font-size: 12px;
      }
    
      .rolesTab ul {
        gap: 12px;
      }

  // .limeMistFullClrbtn{
  //   width: 260px;
  // }
  .socialMediaLinks ul li a img {
    width: 30px;
  }
.GroupSearchinput input{
  height: 40px;
  font-size: 16px;
}
.GroupSearchinput .GroupSearchinput_text{
  height: 40px;
  padding: 0px 16px 0px 0px;
}
.GroupSearchinput .GroupSearchinput_text img{
  width: 24px;
}
  .quickLink ul li button {
    font-size: 16px;
  }

  .footerProjectFrame ul li button {
    font-size: 16px;
  }

  .quickLink ul li {
    margin-bottom: 12px;
  }

  .footerProjectFrame ul li {
    margin-bottom: 12px;
  }

  .topscrollBtn {
    font-size: 14px;
  }

  .limeMistBorderbtn {
    height: 40px;
    font-size: 14px;
    padding: 0px 24px;
  }

  .lightGreenEffectBtn {
    height: 44px;
    font-size: 16px;
    padding: 0px 24px;
  }
    .secondaryBtn{
          font-size: 14px;
          height: 40px;   
    }

  .width250px {
    width: 180px;
  }

  .testimonial_profile img {
    width: 50px;
  }

  br.MobBr_hide {
    display: none;
  }

  .homeProject_InnerContent p {
    font-size: 14px;
  }

  .fontSize16 {
    font-size: 14px;
  }

  .fontSize18 {
    font-size: 14px;
  }

  .fontSize24 {
    font-size: 16px;
  }

  .fontSize32 {
    font-size: 24px;
  }

  .fontSize34 {
    font-size: 26px;
  }

  .fontSize48 {
    font-size: 40px;
  }

  .fontSize40 {
    font-size: 32px;
  }

  .fontSize30 {
    font-size: 22px;
  }

  .careerItem {
    height: 360px;
  }

  .ourStoryAccordion .accordion-body {
    padding: 16px;
  }

  .ourStoryAccordion .accordion-header button {
    font-size: 20px;
    padding: 16px;
  }

  .about_WWA_content h3 {
    font-size: 50px;
  }

  .about_WWA_content p {
    font-size: 24px;
  }

  .about_WWA_InsiteContent p {
    font-size: 16px;
  }

  .cursedBlackBtn {
    font-size: 16px;
    height: 44px;
    padding: 0px 24px;
  }

  .about_whoWeAreItem .about_WWA_content {
    min-height: 320px;
  }

  .about_WWA_InsiteContent {
    min-height: 320px;
  }

  .WWA_Item_leftAnimation .about_WWA_InsiteContent>div:first-child {
    width: 280px;
  }

  .WWA_Item_rightAnimation .about_WWA_InsiteContent>div:first-child {
    width: 280px;
  }

  .About_whoWeareFontsizeIncrease h4 {
    font-size: 100px;
  }

  .clientLogo_item {
    width: 160px;
    height: 160px;
  }

  .clientLogo_item img {
    width: 100px;
  }

  .homeProject_InnerContent h2 {
    font-size: 80px;
  }

  .homeProject_InnerContent h5 {
    font-size: 24px;
  }

  //  .homeProject_InnerContent p {
  //    font-size: 26px;
  //  }


  .mobSpace_mb24 {
    margin-bottom: 24px;
  }

  .flexDirectionColumnReverse {
    flex-direction: column-reverse;
  }

  .servicesItem {
    padding-bottom: 60px;
  }

  .mobSpace_mb34 {
    margin-bottom: 34px;
  }

  .ourStorySecCircle {
    display: none;
  }

  .ourStoryAccordion {
    display: block;
  }


  .whoWeareFontsizeIncrease p {
    font-size: 34px;
  }

  .testimonials_sec .testimonial_Item {
    min-height: 400px;
    max-height: 400px;
  }

  .testimonial_Item .commaIcon {
    padding: 0px;
  }

  .testimonial_Item p {
    font-size: 14px;
  }

  .aboutUs_intro {
    padding: 0px;
  }

  .sectionTitle h2 {
    font-size: 50px;
    line-height: 60px;
    z-index: unset !important;
  }

  .tr__overlay {
    padding: 90px 0px 32px;
  }

  .bg_paragraph p {
    font-size: 50px;
    line-height: 60px;
  }

  .latestUpdate_content h1 {
    font-size: 64px;
    line-height: 70px;
  }

  .Main_menu ul li button {
    font-size: 24px;
    line-height: 1.5;
  }

  .WantToWork_Sec h1 {
    font-size: 50px;
    line-height: 50px;
  }

  .pleftZero {
    padding: 0px 12px !important;
  }

  .Home_bnr .carousel_caption .col-lg-1 {
    display: none;
  }

  .sliderWrapper {
    height: 530px;
  }

  .carousel_caption {
    top: 0px;
    height: calc(100% - 0px);
  }

  .pr40 {
    padding-right: 0px;
  }

  .Home_bnr .bnrMain-content h1 {
    font-size: 60px;
    line-height: 60px;
  }

  .Home_bnr .bnrMain-content h2 {
    font-size: 60px;
    line-height: 60px;
  }

  .Home_bnr .bnrMain-content p {
    font-size: 16px;
  }

  .whoWeare_sec::before {
    content: unset;
  }

  .commonPage_bnr.contactUs_bnr::before {
    content: unset;
  }

  .commonPage_bnr.thought_bnr::before {
    content: unset;
  }

  .whatWedo_sec::before {
    content: unset;
  }

  .projects_home::before {
    content: unset;
  }

  .testimonials_sec::before {
    content: unset;
  }

  .commonPage_bnr.aboutUs_bnr::before {
    content: unset;
  }

  .ourStory_bnr::before {
    content: unset;
  }

  .weHandledSec::before {
    content: unset;
  }

  .ourClients_sec::before {
    content: unset;
  }

  .whoWeare_sec {
    margin: 80px 0px;
  }

  .whoWeareFontsizeIncrease {
    z-index: unset;
    transform: translate(0);
    padding-bottom: 40px;
    padding-top: 34px;
  }

  .whoWeareFontsizeIncrease h4 {
    font-size: 120px;
    line-height: 120px;
    color: var(--drWhite_clr);
  }

  .whoWeareFontsizeIncrease p {
    color: var(--drWhite_clr);
  }

  .whoWeAreItem h3 {
    font-size: 46px;
  }

  .whoWeAreItem p {
    font-size: 24px;
  }

  .whoWeare_sec .row .col-lg-8>div:first-child {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  .whoWeAreItem {
    width: 230px;
    height: 230px;
  }

  .VerticalStepsWrapper>div:first-child>div:first-child>div {
    padding: 0px 0px 40px 40px;
  }

  .VerticalStepsWrapper>div:nth-child(2)>div:first-child>div {
    padding: 40px 0px 40px 40px;
  }

  .VerticalStepsWrapper>div:first-child>div:nth-child(3)>div{
    padding: 0px 40px 40px 0px;
  }

  .VerticalStepsWrapper>div:nth-child(2)>div:nth-child(3)>div{
    padding: 40px 40px 40px 0px;
  }

  .fontSize32 {
    font-size: 24px;
  }

  .wwd_carousel {
    width: 300px;
    // height: 325px;
    height: 400px;
  }

  .wwd_FirstImgSize img {
    width: 300px;
    height: 400px;
  }

  .wwd_SecondImgSize img {
    width: 300px;
    height: 325px;
    object-fit: cover;
  }

  .wwd_ThirdImgSize img {

    width: 300px;
    height: 325px;
    object-fit: cover;
  }

  .wwd_FourthImgSize img {
    width: 300px;
    height: 325px;
    object-fit: cover;
  }

  .bg_paragraph {
    margin: 120px 0px;
    padding: 60px 0px;
  }

  .testimonials_sec {
    position: relative;
    margin: 80px 0px;
  }

  .projects_home {
    padding-bottom: 0px;
  }

  .latestUpdate_sec {
    padding: 0px;
  }

  .testimonials_sec .slick-slide {
    padding: 0px 16px 0px;
  }

  .WantToWork_Sec {
    height: 680px;
    padding: 0px 0px;
  }

  .WantToWork_Sec::after {
    top: 40%;
    left: 30%;
  }

  .WantToWork_Sec::before {
    width: 230px;
    height: 230px;
  }

  .contactUs_bnr {
    padding: 80px 0px;
  }

  .projects_bnr {
    padding: 80px 0px;
  }

  .commonPage_bnr.careers_bnr::before {
    content: unset;
  }

  .commonPage_bnr.projects_bnr::before {
    content: unset;
  }

  .projectsTab ul li button {
    font-size: 16px;
    padding: 0px 24px;
    height: 40px;
  }
.projectsTab ul li button.active{
  padding: 0px 34px;
}
  .projectsTab ul {
    padding-bottom: 120px;
    gap: 16px;
  }

  .ourExpertise_sec::before {
    z-index: unset;
  }

  .ourExpertCircleThree {
    left: 120px;
  }

  .ourExpertCircleFour {
    left: -90px;
    top: 0px;
  }

  .ourExpertCircleSix {
    left: 100px;
    top: -30px;
  }

  .ourProcess_slider {
    padding: 80px 0px 0px 0px;
  }

  .ourProcessItem {
    padding: 20px;
    min-height: 420px;
    max-height: 420px;
  }
.servicesImages img{
  height: 500px;
}
  .ourProcessItem h5 {
    font-size: 36px;
  }

  .ourProcessItem h4 {
    font-size: 26px;
  }

  .fontSize20 {
    font-size: 16px;
  }



  .commonPage_bnr.services_bnr::before {
    content: unset;
  }

  .ourProcess_sec::before {
    content: unset;
  }

  .projectsList .slick-center {
    transform: scale(1);
  }

  .commonPage_bnr.thought_bnr::before {
    content: unset;
  }

  .borderInput {
    font-size: 12px !important;
    height: 40px;
  }

  .GroupBorderinput .GroupBorderinput_text {
  font-size: 12px !important;
    height: 40px;
  }

  .borderSelectInput {
  font-size: 12px !important;
    height: 40px;
  }

    .smallbutton {
      height: 26px;
      font-size: 12px;
      padding: 0px 16px;
    }

  .GroupBorderinput input {
    font-size: 12px !important;
      height: 40px;
  }
.careerPopup .modal-content{
  padding: 24px;
}
  .borderTextarea {
  font-size: 12px !important;
  }

  .limeMistFullClrbtn {
    height: 36px;
    padding: 0px 24px;
    font-size: 14px;
  }

}

// 3+
@media (min-width: 992px) and (max-width: 1026px) {
.projectsList .slick-slide {
     opacity: unset;
  }
    .projectsListItem img {
      min-height: 280px;
      height: 280px;
      max-height: 280px;
    }
.projectsList .slick-prev{
  left: 0;
  z-index: 1;
}
.projectsList .slick-next {
  right: 0;
}
    .projectDetailHeader h1 {
        font-size: 60px;
      }
    
      .fontSize26 {
        font-size: 20px;
      }
    
      .fontSize36 {
        font-size: 30px;
      }

    .careerPopup .modal-header h2 {
        font-size: 26px;
      }
    
      .rolesTab ul li button {
        height: 32px;
        padding: 0px 16px;
        font-size: 12px;
      }
    
      .rolesTab ul {
        gap: 12px;
      }
  .width250px {
    width: auto;
  }

  .ourStorySecCircle {
    display: none;
  }

  .ourStoryAccordion {
    display: block;
  }

  .container {
    max-width: 930px !important;
  }
     .GroupSearchinput input {
       font-size: 18px;
       height: 50px;
     }
.GroupSearchinput .GroupSearchinput_text{
  height: 50px;
}
.GroupSearchinput .GroupSearchinput_text{
  padding: 0px 16px 0px 0px;
}
.secondaryBtn {
  font-size: 14px;
  height: 40px;
}
  .tr__overlay {
    padding: 120px 0px 32px;
  }

  .whoWeareFontsizeIncrease h4 {
    color: var(--drWhite_clr) !important;
    font-size: 160px;
    line-height: 150px;
  }

  .whoWeareFontsizeIncrease {
    z-index: unset;
    transform: translate(0);
    margin-top: 34px;
  }

  .whoWeare_sec {
    margin: 100px 0px 80px;
  }

  .whoWeareFontsizeIncrease p {
    font-size: 24px;
    color: var(--drWhite_clr) !important;
  }

  .lightGreenEffectBtn {
    font-size: 18px;
    height: 40px;
    padding: 0px 24px;
  }


  .limeMistBorderbtn {
    height: 36px;
    font-size: 12px;
    padding: 0px 20px;
  }

  .smallbutton {
    height: 34px;
    font-size: 10px;
    padding: 0px 16px;
  }

  .topscrollBtn {
    font-size: 10px;
  }

  .fontSize16 {
    font-size: 12px;
  }

  .fontSize18 {
    font-size: 12px;
  }

  .fontSize24 {
    font-size: 18px;
  }

  .fontSize32 {
    font-size: 26px;
  }

  .fontSize34 {
    font-size: 28px;
  }

  .fontSize48 {
    font-size: 42px;
  }

  .fontSize40 {
    font-size: 34px;
  }

  .fontSize30 {
    font-size: 24px;
  }



  .careerItem button {
    font-size: 12px;
    height: 34px;
    padding: 0px 24px;
  }

  .careerItem {
    height: 340px;
  }

  .cursedBlackBtn {
    font-size: 14px;
    height: 44px;
    padding: 0px 24px;
  }

  .about_whoWeAreItem .about_WWA_content {
    padding: 24px;
    min-height: 310px;
  }

  .about_WWA_InsiteContent {
    padding: 24px;
    min-height: 310px;
  }

  .about_WWA_content h3 {
    font-size: 60px;
  }

  .about_WWA_content p {
    font-size: 26px;
  }

  .about_WWA_InsiteContent p {
    font-size: 18px;
  }

  .WWA_Item_leftAnimation .about_WWA_InsiteContent>div:first-child {
    width: 230px;
  }

  .WWA_Item_rightAnimation .about_WWA_InsiteContent>div:first-child {
    width: 230px;
  }

  .clientLogo_item {
    width: 200px;
    height: 200px;
  }

  .clientLogo_item img {
    width: 160px;
  }

  .About_whoWeareFontsizeIncrease h4 {
    font-size: 120px;
  }

  .custom_switch span {
    font-size: 20px;
  }


  .whoWeAreItem p {
    font-size: 18px;
  }

  .whoWeAreItem h3 {
    font-size: 44px;
    line-height: 44px;
  }

  .homeProject_InnerContent h2 {
    font-size: 100px;
  }

  .homeProject_InnerContent h5 {
    font-size: 26px;
  }

  .homeProject_InnerContent p {
    font-size: 10px;
  }

  .sidebar_wrapper ul li button {
    font-size: 12px !important;
  }

  .content_wrapper {
    margin-left: 100px;
  }

  .Main_menu ul li button {
    font-size: 44px;
    line-height: 50px;
  }

  .sectionTitle h2 {
    font-size: 60px;
    line-height: 60px;

  }

  .bg_paragraph p {
    font-size: 60px;
    line-height: 60px;
  }

  .WantToWork_Sec h1 {
    font-size: 60px;
    line-height: 60px;
  }

  .latestUpdate_content h1 {
    font-size: 60px;
    line-height: 60px;
  }

  .sliderWrapper {
    height: 600px;
  }

  .Home_bnr .bnrMain-content p {
    font-size: 18px;
  }

  .Home_bnr .bnrMain-content h2 {
    font-size: 80px;
    line-height: 70px;
  }

  .Home_bnr .bnrMain-content h1 {
    font-size: 80px;
    line-height: 70px;
  }

  .bg_paragraph {
    padding: 50px 0px;
  }

  .whoWeAreItem {
    width: 180px;
    height: 180px;
  }

  .whoWeare_sec .row .col-lg-8>div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // gap: 16px;
    gap: 24px;
  }

  .footerProjectFrame ul li button {
    font-size: 14px;
  }

  .quickLink ul li button {
    font-size: 14px;
  }

  .quickLink ul li {
    margin-bottom: 8px;
  }

  .footerProjectFrame ul li {
    margin-bottom: 8px;
  }

  .socialMediaLinks ul li a img {
    width: 34px;
  }

  .fontSize20 {
    font-size: 14px;
  }

  .WantToWork_Sec {
    padding: 80px 0px;
    height: 750px;
  }

  footer {
    padding: 60px 0px;
  }

  .testimonials_sec {
    padding: 80px 0px;
    margin: 0px;
  }

  .testimonial_Item {
    padding: 24px !important;
    min-height: 350px !important;
    max-height: 350px !important;

  }

  .testimonial_Item .commaIcon {
    padding: 0px;
  }

  .testimonial_Item .commaIcon img {
    width: 50px;
  }

  .testimonial_Item p {
    font-size: 12px;
  }

  .testimonial_profile img {
    width: 34px;
  }

  .testimonials_sec::before {
    content: none;
  }

  .projects_home::before {
    content: none;
  }

  .whoWeare_sec::before {
    content: none;
  }

  .whatWedo_sec::before {
    content: none;
  }

  .commonPage_bnr.contactUs_bnr::before {
    content: unset;
  }

  .commonPage_bnr.projects_bnr::before {
    content: unset;
  }

  .commonPage_bnr.careers_bnr::before {
    content: unset;
  }

  .commonPage_bnr.aboutUs_bnr::before {
    content: unset;
  }

  .ourStory_bnr::before {
    content: unset;
  }

  .weHandledSec::before {
    content: unset;
  }

  .ourClients_sec::before {
    content: unset;
  }

  .commonPage_bnr.services_bnr::before {
    content: unset;
  }

  .ourProcess_sec::before {
    content: unset;
  }

  .commonPage_bnr.thought_bnr::before {
    content: unset;
  }

  .servicesContent h1 {
    font-size: 60px;
  }

  .servicesContent h2 {
    font-size: 54px;
    line-height: 60px;
  }

  .servicesImages img {
    width: 350px;
    height: 400px;
  }

  .ourExpertise_sec::before {
    z-index: unset;
  }

  .ourProcess_slider {
    padding: 80px 0px 0px 40px;
  }

  .ourProcessItem {
    padding: 34px 24px 24px;
    min-height: 370px;
    max-height: 370px;
  }

  .ourProcessItem h5 {
    font-size: 38px;
  }

  .ourProcessItem h4 {
    font-size: 28px;
  }

  .ourExpertCircleFour {
    left: -120px;
    top: -40px;
  }

  .contactUs_bnr {
    padding: 80px 0px;
  }

  .wwd_FirstImgSize img {
    // width: 252px;
    width: 320px;
    height: 425px;
  }

  .wwd_SecondImgSize img {
    width: 252px;
    height: 425px;
  }

  .wwd_ThirdImgSize img {
    width: 252px;
    height: 425px;
  }

  .wwd_FourthImgSize img {
    width: 252px;
    height: 425px;
  }

  .wwd_carousel {
    // width: 252px;
    width: 320px;
    height: 425px;
  }

  .toggle_switch {
    width: 120px;
    height: 50px;
  }

  .toggle_switch:before {
    width: 40px;
    height: 40px;
  }

  .toggle_switch:before {
    top: calc(50% - 20px);
    left: 10px;
  }

  .custom_switch {
    padding-bottom: 60px;
  }

  .careerItem_icon {
    width: 60px;
    height: 60px;
  }

  .careerItem_icon img {
    width: 40px;
  }

  .careerPopup .modal-content {
    height: 320px;
  }


  .projectsTab ul li button {
    font-size: 18px;
    height: 40px;
    padding: 0px 24px;
  }
    .projectsTab ul{
      gap: 24px 12px;
    }
        .projectsTab ul li button.active{
          padding: 0px 44px;
        }
  .limeMistFullClrbtn {
    height: 40px;
    padding: 0px 24px;
    font-size: 14px;
  }

  .borderInput {
    font-size: 14px !important;
    height: 50px;
  }

  .GroupBorderinput .GroupBorderinput_text {
    font-size: 14px !important;
    height: 50px;
  }

  .borderSelectInput {
    font-size: 14px !important;
    height: 50px;
  }

  .GroupBorderinput input {
    font-size: 14px !important;
    height: 50px;
  }

  .borderTextarea {
    font-size: 14px !important;
  }
}

// 2+
@media (min-width: 1027px) and (max-width: 1229px) {
  .whoWeareFontsizeIncrease h4{
    font-size: 280px;
    line-height: 260px;
  }
// .projectsList .slick-slide {
//     opacity: unset;
//   }

  .projectsListItem img {
    min-height: 280px;
    height: 280px;
    max-height: 280px;
  }

  .projectsList .slick-prev {
    left: 0;
    z-index: 1;
  }

  .projectsList .slick-next {
    right: 0;
  }



  .projectDetailHeader h1{
    font-size: 76px;
  }
    .fontSize26 {
      font-size: 22px;
    }
        .fontSize36{
          font-size: 32px;
        }
  .careerPopup .modal-header h2{
    font-size: 28px;
  }
    .rolesTab ul li button{
          height: 40px;
          padding: 0px 16px;
          font-size: 14px;
    }
.rolesTab ul{
  gap: 16px;
}


  .GroupSearchinput input{
    font-size: 20px;
  }
    .secondaryBtn{
      height: 40px;
        padding: 0px 24px;
        font-size: 14px;
  }

  .limeMistFullClrbtn {
    height: 40px;
    padding: 0px 24px;
    font-size: 14px;
  }

  .container {
    max-width: 1108px !important;
  }

  .sidebar_wrapper {
    width: 100px;
  }

  .lightGreenEffectBtn {
    font-size: 20px;
    height: 50px;
    padding: 0px 30px;
  }

  .limeMistBorderbtn {
    font-size: 12px;
    height: 40px;
    padding: 0px 24px;
  }

  .smallbutton {
    font-size: 12px;
    padding: 0px 20px;
    height: 36px;
  }

  .fontSize20 {
    font-size: 16px;
  }

  .fontSize18 {
    font-size: 14px;
  }

  .fontSize24 {
    font-size: 20px;
  }

  .fontSize32 {
    font-size: 28px;
  }

  .fontSize34 {
    font-size: 30px;
  }

  .fontSize48 {
    font-size: 44px;
  }

  .fontSize40 {
    font-size: 36px;
  }

  .fontSize30 {
    font-size: 26px;
  }

  .fontSize16 {
    font-size: 14px;
  }

  .ourStoryTab ul li h5 {
    font-size: 28px;
  }

  .about_WWA_content h3 {
    font-size: 76px;
  }

  .about_WWA_content p {
    font-size: 28px;
  }

  .topscrollBtn {
    font-size: 12px;
  }

  .whoWeAreItem p {
    font-size: 28px;
  }

  .whoWeAreItem h3 {
    font-size: 60px;
  }

  .homeProject_InnerContent h2 {
    font-size: 120px;
  }

  .careerItem {
    height: 380px;
  }

  .careerItem button {
    height: 36px;
    font-size: 14px;
    padding: 0px 24px;
  }

  .homeProject_InnerContent h5 {
    font-size: 28px;
  }

  .homeProject_InnerContent p {
    font-size: 14px;
  }

  .sidebar_wrapper ul li button {
    font-size: 12px !important;
  }

  .content_wrapper {
    margin-left: 100px;
  }

  .Main_menu ul li button {
    font-size: 54px;
    line-height: 60px;
  }

  .sectionTitle h2 {
    font-size: 76px;
    line-height: 70px;
  }

  .sliderWrapper {
    height: 600px;
  }

  .Home_bnr .bnrMain-content p {
    font-size: 20px;
  }

  .Home_bnr .bnrMain-content h2 {
    font-size: 100px;
    line-height: 90px;
  }

  .Home_bnr .bnrMain-content h1 {
    font-size: 100px;
    line-height: 90px;
  }

  .bg_paragraph {
    padding: 80px 0px;
  }

  .bg_paragraph p {
    font-size: 76px;
    line-height: 80px;
  }

  .WantToWork_Sec h1 {
    font-size: 76px;
    line-height: 70px;
  }

  .latestUpdate_content h1 {
    font-size: 76px;
    line-height: 70px;
  }




  .whoWeAreItem {
    width: 250px;
    height: 250px;
  }

  .footerProjectFrame ul li button {
    font-size: 16px;
  }

  .quickLink ul li button {
    font-size: 16px;
  }

  .quickLink ul li {
    margin-bottom: 12px;
  }

  .footerProjectFrame ul li {
    margin-bottom: 12px;
  }

  .socialMediaLinks ul li a img {
    font-size: 34px;
  }



  .WantToWork_Sec {
    padding: 80px 0px;
    height: 750px;
  }

  footer {
    padding: 60px 0px;
  }

  .testimonial_Item {
    padding: 24px;
    min-height: 430px !important;
    max-height: 430px !important;
    // min-height: 390px !important;
    // max-height: 390px !important;

  }

  .testimonial_Item .commaIcon {
    padding: 0px;
  }

  .testimonial_Item .commaIcon img {
    width: 60px;
  }

  .testimonial_Item p {
    font-size: 14px;
  }

  .testimonial_profile img {
    width: 50px;

  }

  .testimonials_sec::before {
    width: 600px;
    background-size: contain;
    left: 60px;
    top: 0;
  }

  .projects_home::before {
    width: 600px;
    left: 60px;
    top: -40px;
    background-size: contain;
    background-position: left;
  }

  .whoWeare_sec::before {
    width: 460px;
    left: 60px;
    background-size: contain;
  }

  .whatWedo_sec::before {
    width: 563px;
    left: 60px;
    background-size: contain;
  }

  .commonPage_bnr.contactUs_bnr::before {
    background-size: contain;
    width: 600px;
    right: 70px;
  }

  .commonPage_bnr.careers_bnr::before {
    background-size: contain;
    width: 600px;
    right: 70px;
  }

  .commonPage_bnr.projects_bnr::before {
    background-size: contain;
    width: 600px;
    right: 70px;
  }

  .commonPage_bnr.aboutUs_bnr::before {
    background-size: contain;
    width: 500px;
    right: 70px;
  }

  .ourStory_bnr::before {
    background-size: contain;
    width: 460px;
    left: 60px;
    top: -50px;
    background-position: top;
  }

  .weHandledSec::before {
    background-size: contain;
    width: 460px;
    // right: 70px;

  }

  .ourClients_sec::before {
    background-size: contain;
    width: 460px;
    top: -50px;
  }

  .servicesContent h1 {
    font-size: 76px;
  }

  .servicesContent h2 {
    font-size: 64px;
    line-height: 70px;
  }

  .servicesImages img {
    height: 480px;
  }

  .commonPage_bnr.thought_bnr::before {
    background-size: contain;
    width: 500px;
  }



  .blogItemImage img {
    height: 330px;
  }

  .ourProcessItem h4 {
    font-size: 30px;
  }
.ourProcessItem h5 {
  font-size: 40px;
}
.ourProcessItem{
  padding: 60px 24px 40px;
}

  .commonPage_bnr.services_bnr::before {
    background-size: contain;
    width: 500px;
  }

  .ourProcess_sec::before {
    background-size: contain;
    width: 500px;
    top: -50px;
    background-position: center;
  }

  .projectsTab ul {
    padding-bottom: 100px;
  }

  .careerItem_icon {
    width: 70px;
    height: 70px;
  }

  .careerItem_icon img {
    width: 50px;
  }

  .careerPopup .modal-content {
    height: 460px;
  }

  .projectsTab ul li button {
    font-size: 20px;
    padding: 0px 24px;
  }
.projectsTab ul li button.active{
  padding: 0px 46px;
}
  .about_WWA_InsiteContent p {
    font-size: 20px;
  }

  .cursedBlackBtn {
    font-size: 16px;
    height: 44px;
    padding: 0px 24px;
  }

  .About_whoWeareFontsizeIncrease h4 {
    font-size: 140px;
  }

  .WWA_Item_leftAnimation .about_WWA_InsiteContent>div:first-child {
    width: 250px;
  }

  .WWA_Item_rightAnimation .about_WWA_InsiteContent>div:first-child {
    width: 250px;
  }

  .OtherItemImage img,
  .otherImage_skeleton {
    height: 320px;
  }
}

// 1+
@media (min-width: 1230px) and (max-width: 1440px) {
    .projectsList .slick-prev{
        left: 0;
    }
    .projectsList .slick-next{
        right: 0;
    }

  .whoWeareFontsizeIncrease{
    padding-top: 30px;
  }
.whoWeareFontsizeIncrease h4{
  font-size: 300px;
  line-height: 280px;
}
  .projectsList .slick-center.slick-current {
    transform: scale(240 / 100, 160 / 100);
    //   transform: scale(2.5, 1.7);
    //   transform: scale(2.3, 1.9);
    }

  .About_whoWeareFontsizeIncrease h4 {
    font-size: 160px;
  }
     .ourProcessItem{
      padding: 40px 30px 40px;
      min-height:490px;
      max-height: 490px;
     }
 .ourProcessItem h5{
  font-size: 42px;
 }
 .ourProcessItem h4{
  font-size: 32px;
 }
 .ourProcessItem p{
  font-size: 18px;
 }
  .testimonial_Item p {
    font-size: 17px;
  }

  .testimonial_Item .commaIcon {
    padding-bottom: 0px;
  }

  // .testimonials_sec .testimonial_Item{
  //   padding-bottom: 0px;
  // }
  .sectionTitle h2 {
    font-size: 86px;
  }

  .bg_paragraph p {
    font-size: 86px;
    line-height: 90px;
  }

  .latestUpdate_content h1 {
    font-size: 86px;
    line-height: 90px;
  }

  .WantToWork_Sec h1 {
    font-size: 86px;
    line-height: 90px;
  }

  .servicesContent h1 {
    font-size: 86px;
  }

  .about_WWA_content h3 {
    font-size: 86px;
  }

  .homeProject_InnerContent h2 {
    font-size: 140px;
  }

  .projectDetailHeader h1 {
    font-size: 86px;
  }

  .servicesContent h2 {
    font-size: 74px;
  }

  .bg_paragraph {
    padding: 80px 0px;
  }

  .tr__overlay {
    padding: 150px 0px 32px;
  }

  .Main_menu ul li button {
    font-size: 64px;
    line-height: 70px;
  }

  .careerItem_icon {
    width: 90px;
    height: 90px;
  }

  .careerItem_icon img {
    width: 50px;
  }

  .WWA_Item_leftAnimation .about_WWA_InsiteContent>div:first-child {
    width: 330px;
  }

  .WWA_Item_rightAnimation .about_WWA_InsiteContent>div:first-child {
    width: 330px;
  }

  .about_WWA_InsiteContent p {
    font-size: 22px;
  }
}


// @media (min-width: 1400px) and (max-width: 1535px) {

// }

@media (min-width: 1537px) and (max-width: 1680px) {
  .container {
    max-width: 1530px !important;
  }
}

@media (min-width: 1681px) and (max-width: 1707px) {
  .container {
    max-width: 1530px !important;
  }
}

@media (min-width: 1708px) and (max-width: 1920px) {

  .container {
    max-width: 1723px !important;
  }
}



@media (min-width: 1921px) and (max-width: 2048px) {
  .container {
    max-width: 1837px !important;
  }


}




























@media (max-width: 600px) {

  .skeleton_projectDetailVideo {
    height: 250px !important;
  }

  .skeleton_projectDetailClientName {
    top: 10px !important;
  }

  .skeleton_projectDetailClientLogo {
    bottom: 0 !important;
    top: 20 !important;
  }

}





.custom-cursor .animated-cursor {
  z-index: 9999;
}

.skeleton_projectDetailVideo {
  height: 500px;
}

.skeleton_projectDetailClientName {
  bottom: 10px !important;
}

.skeleton_projectDetailClientLogo {
  width: 60px !important;
  height: 60px !important;
  bottom: 0 !important;
  top: 20 !important;
}



